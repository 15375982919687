<script lang="ts" setup>
import { Field } from '@/models/database/Field'

interface Props {
  field: Field
  showLabel?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showLabel: true,
})

const emit = defineEmits(['update:field'])

function updatingModel() {
  emit('update:field', props.field)
}
</script>

<template>
  <q-input
    v-model="field.value" :label="showLabel ? field.name : undefined"
    :prefix="field.format"
    :rules="[val => /^\d*(\,\d+)?$/.test(val) || 'La valeur doit être un nombre (example : 0,5)']"
    error-message="La valeur doit être un nombre"
    @update:model-value="updatingModel" debounce="500"
  />

</template>

<style lang="scss" scoped>
</style>
