<script setup lang="ts">
import { inject } from 'vue'
import draggable from 'vuedraggable'
import EntityField from './EntityField.vue'

const unsavedChanges = inject("unsavedChanges")

interface Props {
    element: any
}

const props = withDefaults(defineProps<Props>(), {
})

function onChange(event) {
    console.log('onChange', event)
    unsavedChanges.value = true
}

function onClone(event) {
    console.log('onClone', event)
    unsavedChanges.value = true
}
</script>


<template>
    <div style="margin-top: 16px; margin-bottom: 16px;">
        Sous-champs :
    </div>
    <div style="margin-left: 76px;">
        <q-list dense class="">
            <draggable :list="element.fields" :group="{ name: 'g2', pull: 'clone', put: true }" ghost-class="ghost"
                item-key="id" class="q-gutter-sm" :clone="onClone" @change="onChange"  :filter="'.no-drag'" preventOnFilter>
                <template #item="{ element, index }">
                    <EntityField :element="element" style="background-color: rgb(237, 237, 237);"></EntityField>
                </template>
                <template #header>
                    <div v-if="element.fields.length === 0" style="height: 60px; margin-left: 0px;">
                        Aucun élément pour le moment<br />
                        Glissez un premier élément
                    </div>
                </template>
            </draggable>
        </q-list>
    </div>
</template>