<script setup lang="ts">
import { ref, watch, inject, computed, Ref } from 'vue'
import { useRouter } from 'vue-router';

import { DatabaseObject } from '@/models/database/Database'
import { Node } from '@/models/database/Node'
import { NodeType } from '@/models/database/NodeType'
import {createUUID, createObjectStructure, fieldsFromModelFields} from '@/components/common/database/utils'
interface Props {
  databaseObject: DatabaseObject
  selectedNode: Node | null | undefined
  parentIds?: string[]
}
const props = defineProps<Props>()

const router = useRouter();

const emit = defineEmits(['update:selectedNode'])

const treeRef = ref()
let databaseObjectModel = inject('databaseObjectModel')
const treeNodes = computed(() => {
  return props.databaseObject.definitionJson.children
})
const treeSelectedNode = ref(props.selectedNode?.id)
const treeExpandedNodes = ref(props.parentIds ? [...props.parentIds, props.selectedNode?.id] : [props.selectedNode?.id])

const unsavedChanges: Ref<boolean> = inject("unsavedChanges")
let searchText = ref('')
const hideEmpty = ref(false)
console.log('treeNodes', treeNodes.value)

watch(treeSelectedNode, async (nodeId) => {
  let currentNode = treeRef.value.getNodeByKey(nodeId)
  emit('update:selectedNode', currentNode)

  if (currentNode == undefined) {
    return
  }
})

function resetFilter() {
  searchText.value = ''
}

function createListNode(node: Node) {
    console.log("treeExpandedNodes", treeExpandedNodes.value)
    console.log("createListNode", node)

    // Only supports list at the first level
    let child = databaseObjectModel?.definitionJson.children.find((child) => child.id === node.modelId)
    console.log("child", child)
    let newNodeId = createUUID()
    let newNode: Node = {
      name: 'Nouveau ' + node.name,
      id: newNodeId,
      type: NodeType.Page,
      modelId: child.id,
      fields: fieldsFromModelFields(child.fields),
      children: [],
      // value: undefined,
    }
    newNode.children = createObjectStructure(child)
    node.children.push(newNode)
    unsavedChanges.value = true
    treeExpandedNodes.value = [...treeExpandedNodes.value, node.id]
    treeSelectedNode.value = newNode.id
  }

function deleteListNode(node: Node) {
  console.log("deleteListNode", node)
  treeNodes.value.forEach(topNode => {
    topNode.children = topNode.children.filter((child) => child.id !== node.id)
  });
  unsavedChanges.value = true
}

function isEmptyField(field) {
  if (field.type == 'multi') {
    return field.value.length == 0
  } else if (field.type == 'group_field') {
    return field.fields.every(field => isEmptyField(field))
  } else if (field.type == 'array') {
    return field.rows.length == 0
  } else {
    return field.value === undefined || field.value === null || field.value === ''
  }
}

function isEmptyNode(node) {
  return node.fields.every(field => isEmptyField(field))
    && node.children.every(child => isEmptyNode(child))
}

function nodeFilter(node) {
  // Filter based on search text
  const matchesSearch = !searchText.value || node.name.toLowerCase().includes(searchText.value.toLowerCase());
  // Filter based on hideEmpty toggle
  const passesEmptyFilter = !hideEmpty.value || !isEmptyNode(node);

  return matchesSearch && passesEmptyFilter;
}

function getNodeStyle(node) {
  let isSelected = treeSelectedNode.value == node.id
  return isEmptyNode(node) && !isSelected
    ? { color: "grey" } // Grey and italic for empty nodes
    : {};
}

function onTreeClick(nodeId) {
  router.push({ hash: `#item-${nodeId}` });
}
</script>

<template>
  <div class="q-gutter-y-md column" style="width: 100%; max-width: 100%">
    <q-toolbar class="text-primary">
      <q-input v-model="searchText" label="Rechercher" outlined dense filled style="width: 100%">
        <template v-slot:append>
          <q-icon v-if="searchText !== ''" name="clear" class="cursor-pointer" @click="resetFilter" />
        </template>
      </q-input>
      <q-btn dense :icon="hideEmpty ? 'o_visibility_off' : 'o_visibility'" class="q-ml-sm"
        @click="hideEmpty = !hideEmpty" :text-color="hideEmpty ? 'primary' : 'grey'">
        <q-tooltip>{{ hideEmpty ? 'Montrer toutes les sections' : 'Cacher les sections vides' }}</q-tooltip>
      </q-btn>
    </q-toolbar>
  </div>

  <q-scroll-area style="width: 100%; height: calc(100vh - 140px);">
    <q-tree :nodes="treeNodes" node-key="id" label-key="name" v-model:selected="treeSelectedNode" ref="treeRef"
      selected-color="red-10" :filter="searchText+hideEmpty" :filter-method="nodeFilter" style="height: calc(100vh - 50px)"
      class="full-height" :default-expand-all="false" v-model:expanded="treeExpandedNodes" @click="onTreeClick(treeSelectedNode)">
        <template v-slot:default-header="scope">
        <span :style="getNodeStyle(scope.node)">{{ scope.node.name }}</span>
        <q-btn v-if="scope.node.type === NodeType.List" icon="add" class="q-ml-sm" flat dense size="sm" @click.stop="createListNode(scope.node)">
          <q-tooltip>Ajouter</q-tooltip>
        </q-btn>
        <q-btn v-if="scope.node.type !== NodeType.List && scope.node.modelId === '22989694-6bb6-4497-afaa-5dc612882cf1'" icon="clear" class="q-ml-sm" flat dense size="sm" @click.stop="deleteListNode(scope.node)">
          <q-tooltip>Supprimer</q-tooltip>
        </q-btn>
      </template>
    </q-tree>
  </q-scroll-area>
</template>
