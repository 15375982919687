f<script lang="ts" setup>
import { ref, Ref, inject } from 'vue'
import { QTableProps, Dialog, useDialogPluginComponent, DialogChainObject, Notify } from 'quasar'
import { Node } from '@/models/database/Node'
import { NodeType } from '@/models/database/NodeType'
import { DatabaseObject } from '@/models/database/Database'
import { Question } from '@/models/survey/Question'
import { useApi } from '@/store/useApi'
import UserApi from '@/services/api/core/UserApi'
import BaseDialog from '@/components/base/BaseDialog.vue'
import ObjectChildSelectionDialog from '@/components/common/database/ObjectChildSelectionDialog.vue'
import ObjectEditionDialog from '@/components/common/database/ObjectEditionDialog.vue'
import { createUUID, createObjectStructure, fieldsFromModelFields } from '@/components/common/database/utils'

interface Props {
  question: Question
}
const props = defineProps<Props>()
const emit = defineEmits([...useDialogPluginComponent.emits])
const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()
const userApi: UserApi = useApi()

let databaseObjects
let objectModel
let mapList
let mapArray
let listNodeIds
let arrayRowIds
let multipleObjects
const selectedNode = ref(undefined)
const filter = ref('')
const selected: Ref<Array<Node>> = ref([])
const loading = ref(false);
const createButton = ref(false)
const rows: Ref<Array<Node>> = ref([])
const columns: QTableProps['columns'] = [
  {
    name: 'label',
    label: 'Objets',
    field: 'label',
    align: 'left',
  },
  {
    name: 'objectType',
    label: 'Types',
    field: 'objectType',
    align: 'left',
  },
  // {
  //   name: 'informations',
  //   label: 'Informations',
  //   field: 'informations',
  //   align: 'right',
  // },
]

async function beforeShow() {
  loading.value = true
  // Get the right object model
  let databaseObjectModelNames
  try {
    const objectModels = await userApi.getDatabaseObjectModels()
    if (props.question.databaseObjectModelFieldsMapping.length > 1) {
      // Special case of multiarray (no support for list/array submapping)
      mapList = []
      mapArray = []
      multipleObjects = true
      databaseObjectModelNames = props.question.databaseObjectModelFieldsMapping.map((objectModelMapping) => objectModelMapping.databaseObjectModelName)
    } else {
      objectModel = objectModels.find((objectModel) => objectModel.definitionJson.name === props.question.databaseObjectModelFieldsMapping[0].databaseObjectModelName)
      // Check if the mapping involves a list or an array
      const mappingFieldIds = props.question.databaseObjectModelFieldsMapping[0].data.map((d) => d.databaseObjectModelFieldId)
      const results = findListOrArray(objectModel, mappingFieldIds)
      mapList = results.list
      mapArray = results.array
      multipleObjects = mapList.length === 0 && mapArray.length === 0 && (props.question.type === 'array' || props.question.type === 'multiarray' || props.question.type === 'multisign')
      databaseObjectModelNames = [props.question.databaseObjectModelFieldsMapping[0].databaseObjectModelName]
      createButton.value = mapList.length === 0 && mapArray.length === 0
    }
    // Get the objects
    const objects = await userApi.getDatabaseObjects()
    databaseObjects = objects.filter((databaseObject) => {
      // console.log(databaseObject.objectModelName, props.question.databaseObjectModelFieldsMapping[0].databaseObjectModelName)
      return databaseObjectModelNames.includes(databaseObject.objectModelName)
    })
    console.log('databaseObjects for selection', databaseObjects)
    console.log('mapList', mapList, 'mapArray', mapArray)
    rows.value.push(...databaseObjects)
  }  catch (error) {
    console.error("Error fetching data: ", error);
  } finally {
    loading.value = false;
  }
}

/**
 * Finds the lists or arrays in the object model that are involved in the mappingFieldIds.
 *
 * @param {Object} objectModel - The object model to parse.
 * @param {Array<string>} mappingFieldIds - The IDs of fields that are mapped.
 * @returns {Array<Object>} - Contains "List" nodes and "array" fields.
 */
function findListOrArray(objectModel, mappingFieldIds) {
  const results: {
    list: { id: string; name: string }[];
    array: { id: string; name: string }[]
  } = { list: [], array: [] };

  // Recursive helper function to traverse the object model
  function traverse(node, parentList: any = undefined) {
    if (node.type == 'LIST') { parentList = {id: node.id, name: node.name}; };
    // If we are under a list, check if any of the fields are in the mappingFieldIds
    if (parentList && !results.list.includes(parentList)) {
      const matchingFields = node.fields.filter(field => mappingFieldIds.includes(field.id));
      if (matchingFields.length > 0) {
        results.list.push(parentList);
      }
    }
    // Check if there are any arrays in the current node
    const arrayFields = node.fields.filter(field => field.type == 'array');
    // If there are arrays, check if any of the columns are in the mappingFieldIds
    if (arrayFields) {
      arrayFields.forEach(arrayField => {
        const matchingFields = arrayField.columns.filter(field => mappingFieldIds.includes(field.id));
        if (matchingFields.length > 0) {
          results.array.push({
            id: arrayField.id,
            name: arrayField.name,
          });
          if (parentList && !results.list.includes(parentList)) {
            results.list.push(parentList);
          }
        }
      })
    }
    // Traverse the children of the current node, if any
    if (node.children) {
      node.children.forEach((child) => traverse(child, parentList));
    }
  }
  // Start traversal from the root of the object model
  traverse(objectModel.definitionJson);
  console.log('findListOrArray results', results)

  return results;
}

const dialogInstances: DialogChainObject[] = []; // Track dialog instances to close them

/**
 * After clicking on a database object, opens if needed the dialogs to select list nodes or array rows.
 * Emits the selection to the parent component.
 *
 * @param databaseObject The object that was clicked
 */
function onRowClicked(databaseObject) {
  if (mapList.length > 0) {
    // Open another dialog to select a list node
    console.log('mapList', mapList)
    // const childrenRows = getListRows(databaseObject)
    // console.log('childrenRows', childrenRows)
    const dialog = Dialog.create({
      component: ObjectChildSelectionDialog,
      componentProps: {
        // rows: childrenRows,
        // columns: [{ name: 'Objet', label: 'Objet', field: 'name', align: 'left', sortable: true }],
        databaseObject: databaseObject,
        type: 'list',
        modelId: mapList[0].id,
        title: mapList[0].name,
        question: props.question,
        multiSelect: props.question.type === 'array' && (mapArray.length === 0),
      },
    })
      .onOk((nodeIds) => {
        // Remove the mapList element and call again onRowClicked
        // if (nodeIds.length === 0) {
        //   // if nodeIds empty, create a new listNode
        //   let newNodeId = createListNode(databaseObject, mapList[0].id)
        //   nodeIds = [newNodeId]
        // }
        listNodeIds = nodeIds
        console.log('added listNodeIds', listNodeIds)
        mapList.pop()
        onRowClicked(databaseObject)
      })
      .onCancel(() => {
        onRowClicked(databaseObject)
      })
    dialogInstances.push(dialog);
  } else if (mapArray.length > 0) {
    // Open another dialog to select an array row
    console.log('mapArray', mapArray)
    // const result = getArrayRows(databaseObject.definitionJson)
    // console.log('childrenRows', result ? result.rows : [])
    const dialog = Dialog.create({
      component: ObjectChildSelectionDialog,
      componentProps: {
        // rows: result ? result.rows : [],
        // columns: result ? result.columns : [],
        // columns: result ? result.columns.filter(col => props.question.databaseObjectModelFieldsMapping[0].data.map((d) => d.databaseObjectModelFieldId).includes(col.modelId)) : [],
        databaseObject: databaseObject,
        type: 'array',
        modelId: mapArray[0].id,
        title: mapArray[0].name,
        listNodeIds: listNodeIds,
        question: props.question,
        multiSelect: props.question.type === 'array',
      },
    })
      .onOk((rowIds) => {
        // Remove the mapArray element and call again onRowClicked
        // if (rowIds.length === 0) {
        //   // if rowIds empty, we create a new arrayRow
        //   let newRowId = createArrayRow(result.field)
        //   rowIds = [newRowId]
        // }
        arrayRowIds = rowIds
        console.log('added arrayRowIds', arrayRowIds)
        mapArray.pop()
        onRowClicked(databaseObject)
      })
      .onCancel(() => {
        dialog.hide()
      })
    dialogInstances.push(dialog);
  } else {
    // Object / subobject has been selected. Propose the edition if it is a question of type "object"
    if (props.question.type === 'object') {
      const dialog = Dialog.create({
        component: ObjectEditionDialog,
        componentProps: {
          databaseObject: databaseObject,
          listNodeId: listNodeIds ? listNodeIds[0] : undefined,
          arrayRowId: arrayRowIds ? arrayRowIds[0] : undefined,
          questionMappings: props.question.databaseObjectModelFieldsMapping[0].data,
          questionOptions: props.question.options
        },
      })
        .onOk(() => {
          emit('ok', {})
          // Update the DP link of the response
          props.question.answer.dpLink = {
            "databaseObjectId": databaseObject.id,
            "listNodeId": listNodeIds ? listNodeIds[0] : null,
            "arrayRowId": arrayRowIds ? arrayRowIds[0] : null
          }
          dialogInstances.forEach(dialog => dialog.hide());
          dialogInstances.length = 0;
          dialog.hide()
        })
        .onCancel(() => {
        })
    } else {
      emit('ok', { selectedObjects: [databaseObject], listNodeIds, arrayRowIds })
      dialogInstances.forEach(dialog => dialog.hide());
      dialogInstances.length = 0;
    }
  }
}

// function createListNode(databaseObject, listNodeModelId) {
//   // Only supports list at the first level
//   let child = objectModel?.definitionJson.children.find((child) => child.id === listNodeModelId)
//   const newNodeId = createUUID()
//   const newNode: Node = {
//     name: `Nouveau ${child.name}`,
//     id: newNodeId,
//     type: NodeType.Page,
//     modelId: child.id,
//     fields: fieldsFromModelFields(child.fields),
//     children: [],
//     // value: undefined,
//   }
//   newNode.children = createObjectStructure(child)
//   let parentNode = databaseObject.definitionJson.children.find((child) => child.modelId === listNodeModelId)
//   parentNode.children.push(newNode)
// }

// function createArrayRow(arrayField) {
//   const row = {
//     id: createUUID(),
//     fields: []
//   }
//   arrayField.columns.forEach((column: any) => {
//     let rowField: any = {
//       id: createUUID(),
//       name: column.name,
//       type: column.type,
//       modelId: column.modelId,
//       value: ""
//     }
//     if (column.type === "group_field") {
//       rowField.fields = column.fields.map(field => ({
//         ...field,
//         value: "",
//         id: createUUID()
//       }))
//     }
//     if (column.type === "choice") { rowField.choices = column.choices }
//     if (column.type === "number" || column.type === "function") { rowField.format = column.format }
//     if (column.type === "function") { rowField.formula = column.formula }
//     row.fields.push(rowField)
//   })
//   arrayField.rows.push(row)
//   return row.id
// }

function onAddButtonClick() {
  emit('ok', { selectedObjects: selected.value })
}

async function onNewObjectClick() {
  // Open dialog to create a new object. Only ask for the info needed in that question
  const id = createUUID()
  const newObject: DatabaseObject = {
    objectModelId: objectModel.id,
    id: id,
    definitionJson: {
      name: 'Nouveau "' + objectModel.name + '"',
      id: id,
      modelName: objectModel.name,
      type: "OBJECT",
      fields: fieldsFromModelFields(objectModel.definitionJson.fields),
      children: createObjectStructure(objectModel.definitionJson)
    },
  }
  let result = await userApi.createDatabaseObject(newObject.id, newObject.objectModelId, newObject.definitionJson);
  Notify.create(`${objectModel.name} créée avec succès`)
  const dialog = Dialog.create({
    component: ObjectEditionDialog,
    componentProps: {
      databaseObject: result,
      questionMappings: props.question.databaseObjectModelFieldsMapping[0].data,
      questionOptions: props.question.options
    },
  })
    .onOk((data) => {
      emit('ok', { selectedObjects: [data] })
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function objectFilter(rows) {
  return rows.filter(row => row.definitionJson.name.toLowerCase().includes(filter.value.toLowerCase()))
}
</script>

<template>
  <q-dialog ref="dialogRef" @before-show="beforeShow">
    <BaseDialog :title="question.label || 'Sélection'" @on-dialog-cancel="onDialogCancel()" @hide="onDialogHide()">
      <template #body>
        <div class="col">
          <q-table v-if="!multipleObjects" :rows="rows" :columns="columns" row-key="id" flat bordered
            v-model:selected="selectedNode" :filter="filter" :filter-method="objectFilter" hide-bottom filt
            :loading="loading">
            <template #bottom></template>
            <template #body="props">
              <q-tr :props="props" class="cursor-pointer">
                <q-td key="label" :props="props" @click="onRowClicked(props.row)">
                  {{ props.row.definitionJson.name }}
                </q-td>
                <q-td key="objectType" :props="props" @click="onRowClicked(props.row)">
                  {{ props.row.objectModelName }}
                </q-td>
                <!-- <q-td key="informations" :props="props">
                  <q-btn flat round color="primary" icon="info_outline">
                    <q-popup-proxy>
                      <q-banner class="q-pa-none">
                        <q-list dense>
                          <div v-for="field in props.row.fields">
                            <q-item v-if="typeof field.value !== 'object' && field.value">
                              <q-item-section side>{{ field.name }}</q-item-section>
                              <q-item-section> {{ field.value }}</q-item-section>
                            </q-item>
                          </div>
                        </q-list>
                      </q-banner>
                    </q-popup-proxy>
                  </q-btn>
                </q-td> -->
              </q-tr>
            </template>
            <template #top-left>
              <q-input filled debounce="300" color="primary" dense class="filter-input" v-model="filter">
                <template #prepend>
                  <q-icon name="search" />
                </template>
              </q-input>
            </template>
            <template #top-right>
              <q-btn v-if="createButton" color="primary" label="Nouveau" @click="onNewObjectClick()" />
            </template>
          </q-table>
          <q-table v-if="multipleObjects" :filter="filter" :rows="rows" :columns="columns" row-key="id" flat bordered selection="multiple"
            v-model:selected="selected">
            <template #body="props">
              <q-tr :props="props" class="cursor-pointer">
                <q-td auto-width>
                  <q-checkbox v-model="props.selected" color="primary"/>
                </q-td>
                <q-td key="label" :props="props">
                  {{ props.row.definitionJson.name }}
                </q-td>
                <q-td key="objectType" :props="props">
                  {{ props.row.objectModelName }}
                </q-td>
                <q-td key="informations" :props="props">
                  <q-btn flat round color="primary" icon="info_outline">
                    <q-popup-proxy>
                      <q-banner class="q-pa-none">
                        <q-list dense>
                          <div v-for="field in props.row.fields">
                            <q-item v-if="typeof field.value !== 'object' && field.value">
                              <q-item-section side>{{ field.name }}</q-item-section>
                              <q-item-section> {{ field.value }}</q-item-section>
                            </q-item>
                          </div>
                        </q-list>
                      </q-banner>
                    </q-popup-proxy>
                  </q-btn>
                </q-td>
              </q-tr>
            </template>
            <template #top-left>
              <q-input filled debounce="300" color="primary" dense class="filter-input" v-model="filter">
                <template #prepend>
                  <q-icon name="search" />
                </template>
              </q-input>
            </template>
          </q-table>
        </div>
      </template>
      <template #actions>
        <q-btn v-if="multipleObjects" :disable="selected.length === 0" flat label="Ajouter" color="primary" @click="onAddButtonClick" v-close-popup />
      </template>
    </BaseDialog>
  </q-dialog>
</template>

<style lang="scss" scoped>
.cell {
  padding: 0px !important;
}

.filter-input {
  width: 300px;
}

.tree {
  overflow: scroll;
}

.sticky-header-table {

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th {
    background-color: #e4e4e4;
  }
}

.sticky-header-column-table {
  /* specifying max-width so the example can
    highlight the sticky column on any browser window */
  max-width: 100%;

  td:first-child {
    /* bg color is important for td; just specify one */
    background-color: #e4e4e4 !important;
  }

  tr th {
    position: sticky;
    /* higher than z-index for td below */
    z-index: 2;
    /* bg color is important; just specify one */
    background: #fff;
  }

  /* this will be the loading indicator */
  thead tr:last-child th {
    /* height of all previous header rows */
    top: 48px;
    /* highest z-index */
    z-index: 3;
  }

  thead tr:first-child th {
    top: 0;
    z-index: 1;
  }

  tr:first-child th:first-child {
    /* highest z-index */
    z-index: 3;
  }

  td:first-child {
    z-index: 1;
  }

  td:first-child,
  th:first-child {
    position: sticky;
    left: 0;
  }
}
</style>
