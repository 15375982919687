<script lang="ts" setup>
import { computed, ref } from "vue";
import UserApi from "@/services/api/core/UserApi";
import { useApi } from "@/store/useApi";
import { DatabaseObject } from "@/models/database/Database";
import { findMatchingFields, updateMatchingFields } from "@/components/common/database/utils"
import { Dialog, useDialogPluginComponent } from "quasar";

interface Props {
  databaseObject: DatabaseObject,
  listNodeId?: any,
  arrayRowId?: any,
  questionMappings: any[],
  questionOptions: any[],
}
const props = defineProps<Props>();

const emit = defineEmits([...useDialogPluginComponent.emits]);
const userApi: UserApi = useApi()

let matchingFields = findMatchingFields(props.databaseObject.definitionJson, props.listNodeId, props.arrayRowId, props.questionMappings)
let objectFields = Object.fromEntries(matchingFields.map(field => [field.variableFieldName, field.value]))
let types = Object.fromEntries(matchingFields.map(field => [field.variableFieldName, field.type]))
// console.log('matchingFields', matchingFields)
// console.log('objectFields', objectFields)

// Build the current answer based on either the object fields or the question answers
const dialogFields = ref({})
props.questionOptions.forEach(option => {
  if (!option.answer.value) {
    dialogFields.value[option.label] = objectFields[option.label]
  } else if (!objectFields[option.label]) {
    dialogFields.value[option.label] = option.answer.value
  } else if (objectFields[option.label] == option.answer.value) {
    dialogFields.value[option.label] = option.answer.value
  } else {
    // Two distinct values
    dialogFields.value[option.label] = { 'survey': option.answer.value, 'object': objectFields[option.label] }
  }
})
const hasConflict = computed(() => {
  return Object.values(dialogFields.value).some(field => typeof field === 'object' && field && 'survey' in field)
})

// Helper functions
function getInputType(field) {
  // Map field types to input types (e.g., text, number, date)
  const typeMapping = {
    string: "text",
    number: "number",
    date: "date",
  };
  return typeMapping[field.type] || "text";
}

// Update object and answer
async function onConfirm() {
  // Update object
  console.log('dialogFields', dialogFields.value)
  updateMatchingFields(props.databaseObject.definitionJson, props.listNodeId, props.arrayRowId, props.questionMappings, dialogFields.value)
  console.log('definitionJson', props.databaseObject.definitionJson)
  await userApi.updateDatabaseObject(props.databaseObject.id, props.databaseObject.definitionJson);

  // Update answer
  props.questionOptions.forEach(option => {
    option.answer.value = dialogFields.value[option.label]
  })

  emit("ok");
}

function deleteLink() {
  // Delete the link with the Dossier permanent and close dialog
  Dialog.create({
    title: 'Supprimer',
    message: 'Etes-vous sur de vouloir supprimer le lien avec le dossier permanent ?',
    style: 'white-space: pre-wrap;',
    cancel: true,
  }).onOk(async () => {
    emit('ok',  {deleteLink: true})
  })
}

function getUrl() {
  let url = `/clients/${props.databaseObject.clientId}/database/${props.databaseObject.id}`
  if (props.listNodeId) return url + `#item-${props.listNodeId}`
  return url + `#item-${matchingFields[0].pageId}`
}
</script>

<template>
<q-dialog>
  <q-card style="width: 700px; max-width: 80vw; max-height: 80vh">
    <q-card-section class="row items-center justify-between">
      <div class="text-h6">Modifier</div>
      <div v-if="databaseObject">
        <!-- Link to the database object -->
        <q-icon name="r_close" size="xs" class="cursor-pointer q-mr-sm unlink-button" @click="deleteLink"/>
        <a :href="getUrl()" target="_blank" rel="noopener noreferrer">
          <q-icon name="open_in_new" size="xs" /> {{ databaseObject.definitionJson.name }}
          <q-tooltip anchor="bottom right" self="top middle">
            Ouvrir les dossiers permanents
          </q-tooltip>
        </a>
      </div>
    </q-card-section>

    <q-card-section>
      <div v-if="hasConflict" >
        <q-icon name="warning" size="xs" color="warning" style="vertical-align: middle;"/>
         Il y a des différences entre la <span class="text-green">réponse actuelle</span>
        et le <span class="text-blue">dossier permanent.</span>
          <br>Veuillez choisir les informations appropriées. Le dossier permanent sera également mis à jour.
      </div>
      <div v-else>
        Les informations suivantes seront mises à jour dans le questionnaire et dans le dossier permanent.
      </div>
    </q-card-section>

    <q-card-section>
      <div v-for="option in questionOptions" :key="option.id" class="q-mb-md">
        <!-- If the answer has two conflicting values, propose resolution -->
        <div v-if="typeof dialogFields[option.label] === 'object' && 'survey' in dialogFields[option.label]" class="conflict-values">
          {{ option.label }} <q-icon name="warning" size="xs" color="warning" />

          <div class="q-mt-sm">
            <q-btn
              v-for="(value, source) in dialogFields[option.label]"
              :key="source"
              :color="source === 'survey' ? 'green' : 'blue'"
              @click="dialogFields[option.label] = value"
              class="q-mr-sm"
              :disable="source === 'survey' && types[option.label] === 'function'"
            >
              {{ value }}
              <q-tooltip anchor="bottom right" self="top middle" :delay="500" v-if="source === 'survey' && types[option.label] === 'function'">
                Ce champs étant issu d'un calcul, la valeur du dossier permanent ne peut pas être modifiée via le questionnaire.
                Veuillez la modifier directement dans le dossier permanent.
              </q-tooltip>
              <q-tooltip anchor="bottom right" self="top middle" :delay="500" v-else-if="source === 'survey'">
                Choisir la valeur du dossier permanent.
              </q-tooltip>
              <q-tooltip anchor="bottom right" self="top middle" :delay="500" v-else-if="source === 'object'">
                Choisir la valeur du dossier permanent.
              </q-tooltip>
            </q-btn>
          </div>
        </div>
        <!-- Else, show the input -->
        <div v-else>
          <!-- TODO: test "bool". And add formats when types[option.label]='date' -->
          <q-input v-if="option.type === 'text' || option.type === 'number' || option.type === 'date' || option.type === 'bool'"
          v-model="dialogFields[option.label]"
          :label="option.label"
          :type="getInputType(option)"
          dense outlined
          />
          <q-select v-if="option.type === 'choice'"
          v-model="dialogFields[option.label]" :options="option.options"
          :label="option.label" dense outlined/>
          <q-option-group v-if="option.type === 'multi_choice'"
          v-model="dialogFields[option.label]" :options="option.options"
          type="checkbox" dense outlined/>
        </div>
      </div>
    </q-card-section>

    <q-card-actions align="right">
      <q-btn flat label="Annuler" color="negative" @click="emit('hide')" />
      <q-btn flat label="Confirmer" color="primary" @click="onConfirm" :disable="hasConflict" />
    </q-card-actions>
  </q-card>
</q-dialog>
</template>


<style lang="scss" scoped>
.conflict-values {
  padding: 8px 12px; /* Same padding as q-input */
  border: 1px solid lightgray; /* Use Quasar primary color */
  border-radius: 4px; /* Matches q-input rounded corners */
  outline: none; /* Remove browser default focus outline */
}
/* Add hover effect similar to q-input */
.conflict-values:hover {
  border-color: var(--q-color-primary-light);
}
/* Add focus effect similar to q-input */
.conflict-values:focus-within {
  border-color: var(--q-color-primary);
  box-shadow: 0 0 4px var(--q-color-primary-light);
}
.unlink-button {
  border-radius: 40%;
  background-color: $red-6;
  color: white
}
</style>
