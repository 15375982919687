<script lang="ts" setup>
import { Field } from '@/models/database/Field'
import TextField from "./TextField.vue"
import ChoiceField from "./ChoiceField.vue"
import MultipleChoiceField from "./MultipleChoiceField.vue"
import NumberField from "./NumberField.vue"
import DateField from "./DateField.vue"
import WysiwygTextField from "./WysiwygTextField.vue"
import FunctionField from "./FunctionField.vue"
import GroupField from './GroupField.vue'
import FileField from './FileField.vue'

interface Props {
    field: Field
    showLabel?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    showLabel: true,
})

const emit = defineEmits(['update:field'])

function onFieldChanged() {
  emit('update:field', props.field)
}

</script>

<template>
    <div style="border: 0.5px solid #ccc; padding: 16px;" class="d-flex flex-wrap">
        <div v-if="showLabel" class="q-py-sm" style="font-weight: 500">
            {{ field.name }}
        </div>
        <div v-if="field.fields != undefined" v-for="subfield in field.fields" style="flex: 1 1 calc(20% - 8px); min-width: 100px">
            <TextField v-if="subfield.type === 'text'" :field="subfield" @update:field="onFieldChanged"/>
            <WysiwygTextField v-if="subfield.type === 'wysiwyg_text'" :field="subfield" @update:field="onFieldChanged"/>
            <NumberField v-if="subfield.type === 'number'" :field="subfield" @update:field="onFieldChanged"/>
            <DateField v-if="subfield.type === 'date'" :field="subfield" @update:field="onFieldChanged"/>
            <ChoiceField v-if="subfield.type === 'choice'" :field="subfield" @update:field="onFieldChanged"/>
            <MultipleChoiceField v-if="subfield.type === 'multi_choice'" :field="subfield" @update:field="onFieldChanged"/>
            <FileField v-if="subfield.type === 'file'" :field="subfield" @update:field="onFieldChanged"/>
            <GroupField v-if="subfield.type === 'group_field'" :field="subfield" @update:field="onFieldChanged"/>
            <FunctionField v-if="subfield.type === 'function'" :field="subfield" @update:field="onFieldChanged" :otherFields="field.fields" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.table {
    margin-top: 10px;
}

h3 {
    font-size: medium;
}
</style>
