<script lang="ts" setup>
import { ref, Ref, computed } from 'vue'
import { Dialog, useDialogPluginComponent } from 'quasar'
import UserApi from "@/services/api/core/UserApi";
import { useApi } from "@/store/useApi";
import { Question } from '@/models/survey/Question'
import BaseSelect from '@/components/base/BaseSelect.vue'
import { findMatchingFields, updateMatchingFields } from "@/components/common/database/utils"

interface Props {
  question: Question
  isEdit: boolean
  form?: any
  databaseObjectId?: string,
  listNodeId?: any,
  arrayRowId?: any,
  questionMappings: any[],
}
const props = withDefaults(defineProps<Props>(), {
  form: {},
})
const userApi: UserApi = useApi()

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const options: Ref<Array<any>> = ref([])
const optionSelected: Ref<string> = ref('')
const fields: Ref<any> = ref({})
const editForm: any = JSON.parse(JSON.stringify(props.form))
const arrayForm: any = JSON.parse(JSON.stringify(props.question.options))
let objectFields, types, databaseObject
let matchingFields
const hasConflict = computed(() => {
  return Object.values(fields.value.fields).some(field => typeof field === 'object' && field && 'survey' in field)
})

const booleanSelectValues = [
  {
    label: 'Oui',
    value: true,
  },
  {
    label: 'Non',
    value: false,
  },
]

function optionChanged() {
  fields.value = JSON.parse(
    JSON.stringify(
      arrayForm.find((option, key) => {
        return option.type === optionSelected.value
      }),
    ),
  )
}

function onHide() {
  fields.value = {}
}

async function beforeShow() {
  if (props.isEdit) {
    optionSelected.value = JSON.parse(JSON.stringify(editForm.type))
    fields.value = JSON.parse(JSON.stringify(editForm))
  } else {
    arrayForm.map((option) => {
      options.value.push(option.type)
    })
    optionSelected.value = options.value[0]
    fields.value = JSON.parse(
      JSON.stringify(
        arrayForm.find((option, key) => {
          return option.type === optionSelected.value
        }),
      ),
    )
  }

  if (props.databaseObjectId) {
    databaseObject = await userApi.getDatabaseObject(props.databaseObjectId)
    matchingFields = findMatchingFields(databaseObject.definitionJson, props.listNodeId, props.arrayRowId, props.questionMappings)
    objectFields = Object.fromEntries(matchingFields.map(field => [field.variableFieldName, field.value]))
    types = Object.fromEntries(matchingFields.map(field => [field.variableFieldName, field.type]))
  } else { // case of a manual entry
    objectFields = {}
  }
  console.log('objectFields', objectFields)

  // Build the current answer based on either the object fields or the question answers
  fields.value.fields.forEach(field => {
    if (!field.value) {
      field.value = objectFields[field.label] ? objectFields[field.label] : ''
    } else if (field.value && objectFields[field.label] && field.value !== objectFields[field.label]) {
      // Two distinct values
      field.value = {'survey': field.value, 'object': objectFields[field.label]}
    }
  })
}

function getInputType(field) {
  // Map field types to input types (e.g., text, number, date)
  const typeMapping = {
    string: "text",
    number: "number",
    date: "date",
  };
  return typeMapping[field.type] || "text";
}

// Update object and answer
async function onConfirm() {
  fields.value.fields.forEach((field) => {
    if (field.value === undefined) {
      field.value = ''
    }
  })
  if (databaseObject) {
    // Update object
    let valueByLabel = {}
    fields.value.fields.forEach(field => {
      valueByLabel[field.label] = field.value
    })
    updateMatchingFields(databaseObject.definitionJson, props.listNodeId, props.arrayRowId, props.questionMappings, valueByLabel)
    console.log('definitionJson', databaseObject.definitionJson)
    await userApi.updateDatabaseObject(databaseObject.id, databaseObject.definitionJson);
  }

  emit('ok', fields.value)
}

function deleteLink() {
  // Delete the link with the Dossier permanent and close dialog
  Dialog.create({
    title: 'Supprimer',
    message: 'Etes-vous sur de vouloir supprimer le lien avec le dossier permanent ?',
    style: 'white-space: pre-wrap;',
    cancel: true,
  }).onOk(async () => {
    emit('ok',  {...fields.value, deleteLink: true})
  })
}

function getUrl() {
  let url = `/clients/${databaseObject.clientId}/database/${databaseObject.id}`
  if (props.listNodeId) return url + `#item-${props.listNodeId}`
  return url + `#item-${matchingFields?.[0].pageId}`
}
</script>

<template>
  <q-dialog ref="dialogRef" @before-show="beforeShow()" @hide="onHide()">
    <q-card style="width: 700px; max-width: 80vw; max-height: 80vh">
      <q-card-section class="row items-center justify-between">
        <div class="text-h6">{{question.label}}</div>
        <!-- Link to the database object -->
        <div v-if="databaseObject" >
          <q-icon name="close" size="xs" class="cursor-pointer q-mr-sm unlink-button" @click="deleteLink" />
          <a :href="getUrl()" target="_blank" rel="noopener noreferrer">
            <q-icon name="open_in_new" size="xs" /> {{ databaseObject.definitionJson.name }}
            <q-tooltip anchor="bottom right" self="top middle">
              Ouvrir les dossiers permanents
            </q-tooltip>
          </a>
        </div>
      </q-card-section>

      <q-card-section v-if="databaseObject">
        <div v-if="hasConflict" >
          <q-icon name="warning" size="xs" color="warning" style="vertical-align: middle;"/>
           Il y a des différences entre la <span class="text-green">réponse actuelle</span>
          et le <span class="text-blue">dossier permanent.</span>
            <br>Veuillez choisir les informations appropriées. Le dossier permanent sera également mis à jour.
        </div>
        <div v-else>
          Les informations suivantes seront mises à jour dans le questionnaire et dans le dossier permanent.
        </div>
      </q-card-section>

      <q-card-section>
        <BaseSelect
          class="q-mb-md"
          id="option-selector"
          v-model="optionSelected"
          :options="options"
          :readonly="isEdit"
          @update:model-value="optionChanged"
        />
        <div v-for="field in fields.fields" :key="field.id" class="q-mb-md">
          <!-- If the answer has two conflicting values, propose resolution -->
          <div v-if="typeof field.value == 'object' && 'survey' in field.value" class="conflict-values">
            {{ field.label }}  <q-icon name="warning" size="xs" color="warning" />

            <div class="q-mt-sm">
              <q-btn
                v-for="(value, source) in field.value"
                :key="source"
                :color="source === 'survey' ? 'green' : 'blue'"
                @click="field.value = value"
                class="q-mr-sm"
                :disable="source === 'survey' && types[field.label] === 'function'"
            >
                {{ value }}
                <q-tooltip anchor="bottom right" self="top middle" :delay="500" v-if="source === 'survey' && types[field.label] === 'function'">
                  Ce champs étant issu d'un calcul, la valeur du dossier permanent ne peut pas être modifiée via le questionnaire.
                  Veuillez la modifier directement dans le dossier permanent.
                </q-tooltip>
                <q-tooltip anchor="bottom right" self="top middle" :delay="500" v-else-if="source === 'survey'">
                  Choisir la valeur du dossier permanent.
                </q-tooltip>
                <q-tooltip anchor="bottom right" self="top middle" :delay="500" v-else-if="source === 'object'">
                  Choisir la valeur du dossier permanent.
                </q-tooltip>
              </q-btn>
            </div>
          </div>
          <!-- Else, show the input -->
          <div v-else>
            <q-input v-if="field.type === 'text' || field.type === 'number' || field.type === 'date'"
            v-model="field.value"
            :label="field.label"
            :type="getInputType(field)"
            dense outlined
            />
            <!-- <div v-if="option.type === 'bool'" class="column">
              <BaseSelect clearable v-model="field.value" :options="booleanSelectValues" emit-value map-options>
              </BaseSelect>
            </div>
            <div v-if="option.type === 'date'">
              <BaseDatePicker v-model="field.value" :label="option.label"> </BaseDatePicker>
            </div> -->
          </div>
        </div>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="Annuler" color="negative" @click="emit('hide')" />
        <q-btn flat label="Confirmer" color="primary" @click="onConfirm" :disable="hasConflict" />
      </q-card-actions>
    </q-card>

  </q-dialog>
</template>
<style lang="scss" scoped>
.unlink-button {
  border-radius: 40%;
  background-color: $red-6;
  color: white
}
</style>
