<script lang="ts" setup>
import { watch, inject, computed } from 'vue'
import { Field } from '@/models/database/Field'

interface Props {
  field: Field
  otherFields: Field[]
  showLabel?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showLabel: true,
})

const helpers = {
  dateDiff: (start, end, format) => {
    if (!start || !end) return "Indéfini";
    const startDate = new Date(start.split("/").reverse().join("-")); // YYYY-MM-DD
    const endDate = new Date(end.split("/").reverse().join("-")); // YYYY-MM-DD
    const diffMilliseconds = endDate - startDate;

    switch (format.toLowerCase()) {
      case "year":
        var result = endDate.getFullYear() - startDate.getFullYear();
        var m = endDate.getMonth() - startDate.getMonth();
        if (m < 0 || (m === 0 && endDate.getDate() < startDate.getDate())) {
            result--;
        }
        return result;
      case "month":
        var result = endDate.getMonth() - startDate.getMonth();
        var d = endDate.getDate() - startDate.getDate();
        if (d < 0) {
            result--;
        }
        return result;
      case "week":
        return Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24 * 7));
      case "day":
        return Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));
      default:
        throw new Error("Invalid format: must be 'year', 'month', 'week', or 'day'");
    }
  },
  today: () => {
    return new Date().toLocaleDateString('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' });
  },
  dateAdd: (date, number, unit) => {
    if (!date) return "Indéfini";
    const baseDate = new Date(date.split("/").reverse().join("-"));

    switch (unit.toLowerCase()) {
      case "year":
        baseDate.setFullYear(baseDate.getFullYear() + number);
        break;
      case "month":
        baseDate.setMonth(baseDate.getMonth() + number);
        break;
      case "week":
        baseDate.setDate(baseDate.getDate() + number * 7);
        break;
      case "day":
        baseDate.setDate(baseDate.getDate() + number);
        break;
      default:
        throw new Error("Invalid unit: must be 'year', 'month', 'week', or 'day'");
    }

    return baseDate.toLocaleDateString('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' });
  }
}

const computedValue = computed(() => {
  try {
    const paramKeys = [...Object.keys(helpers), ...props.otherFields.map((field) => field.name.replace(/\W+/g, '_'))];
    const paramValues = [...Object.values(helpers), ...props.otherFields.map(
      (field) => field.type === 'number' ? Number(field.value.replace(/,/g, '.')) : field.value
    )];
    console.log('paramKeys', paramKeys, 'paramValues', paramValues)
    const formula = props.field.formula?.replace(/{(.*?)}/g, (_, key) => key.replace(/\W+/g, '_'));
    console.log('formula', formula)

    const formulaFunc = new Function(
      ...paramKeys,
      `return ${formula};`
    );
    let result = formulaFunc(...paramValues)
    if (typeof result === 'number') { result = result.toLocaleString('fr-FR') }
    return result
  } catch (error) {
    console.log("Erreur:",error)
    return "Erreur"
  }
})

function updateValue() {
  props.field.value = computedValue.value
}

updateValue()

watch(() => props.otherFields, () => {
  updateValue()
}, { deep: true })

</script>

<template>
  <q-input
    v-model="field.value" :label="showLabel ? field.name : undefined"
    :prefix="field.format"
    readonly
  />
</template>

<style lang="scss" scoped>
</style>

