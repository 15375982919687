<script lang="ts" setup>
import { ref, Ref, inject, watch, computed } from 'vue'
import { Dialog } from 'quasar'
import * as XLSX from 'xlsx/xlsx.mjs';
import { Field } from '@/models/database/Field'
import TextField from "./TextField.vue"
import NumberField from "./NumberField.vue"
import ChoiceField from "./ChoiceField.vue"
import MultipleChoice from "./MultipleChoiceField.vue"
import DateField from "./DateField.vue"
import WysiwygTextField from "./WysiwygTextField.vue"
import FunctionField from "./FunctionField.vue"
import FileField from "./FileField.vue"
import GroupField from "./GroupField.vue"
import ImportCsvDialog from "./ImportCsvDialog.vue"

interface Props {
  field: Field
  showLabel?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showLabel: true,
})

const emit = defineEmits(["update:field"]);

interface Column {
  name: string;
  label: string;
  field: string;
  align: string;
  sortable: boolean;
  type: string;
  fields?: Array<Field>;
  choices?: string;
  format?: string;
}
interface Row {
  id: string;
  fields: Array<{
    name: string;
    type: string;
    value: string;
  }>;
}
const columns: Ref<Array<Column>> = ref([]);
const visibleColumns: Ref<Array<string>> = ref([]);
const rows: Ref<Array<Row>> = ref([]);
const filters = ref({});
const hasFilters = computed(() => {
  return !Object.values(filters.value).every(f => f.length === 0)
})
initializeTableData()

function initializeTableData() {
  // Initialize columns
  columns.value = [{
    name: "actions",
    label: "",
    field: "actions",
    align: 'left',
    sortable: false,
    type: "actions"
  }].concat(props.field.columns.map((column) => ({
    name: column.name,
    label: column.name,
    field: column.name,
    align: 'left',
    sortable: false,
    type: column.type,
    choices: column.choices,
    format: column.format,
    fields: column.fields,
    // ...(column.choices ? { choices: column.choices } : {}), // only add options if the field column has such a property
    // sort: (a, b) => a.valued > b.value ? 1 : -1, // TODO: make it work
  })));
  // Initialize rows
  rows.value = props.field.rows?.map((row) => ({
    id: row.id || createUUID(),
    fields: row.fields.map((field) => ({
      id: field.id,
      modelId: field.modelId,
      name: field.name,
      type: field.type,
      value: field.value || "",
      ...(field.type === "choice" ? { choices: field.choices } : {}),
      ...(field.type === "function" ? { formula: field.formula } : {}),
      ...(field.type === "group_field" ? { fields: field.fields } : {}),
      ...(field.type === "number" || field.type === "function" ? { format: field.format } : {}),
    })),
  })) || [];
  // Initialize filters
  filters.value = {};
  columns.value.forEach((column) => {
    filters.value[column.name] = [];
  });
  visibleColumns.value = columns.value.map((column) => column.name);
}

watch(
  () => props.field,
  () => {
    initializeTableData()
  },
  { immediate: true }
);

const filteredRows = computed(() => {
  console.log("filters", filters.value, Object.values(filters.value))
  return rows.value.filter((row) => {
    return Object.keys(filters.value).every((column) => {
      const selectedFilters = filters.value[column];
      if (!selectedFilters || selectedFilters.length === 0) {
        return true; // No filter applied for this column
      }
      return selectedFilters.includes(row.fields.find((field) => field.name === column)?.value);
    });
  });
});

const uniqueValues = computed(() => {
  return columns.value.reduce((acc, col) => {
    acc[col.name] = Array.from(new Set(rows.value.map((row) => row.fields.find((field) => field.name === col.name)?.value)));
    return acc;
  }, {});
});

function clearFilters() {
  Object.values(filters.value).forEach(f => f.length = 0)
}

const showSums = computed(() => columns.value.some(column => column.type === "number"));
const rowSums = computed(() => {
  const sums = {};
  columns.value.filter((column) => column.type === "number").forEach((column) => {
    sums[column.name] = 0;
    for (const row of filteredRows.value) {
      const field = row.fields.find((field) => field.name === column.name);
      try { sums[column.name] += parseFloat(field.value.replace(/,/g, '.')) || 0 }
      catch { }
    };
    if (sums[column.name] == 0) { sums[column.name] = '' }
    sums[column.name] = sums[column.name].toLocaleString('fr-FR')
    if (column.format) { sums[column.name] = column.format + ' ' + sums[column.name] }
  });
  return sums;
});

// Watch for changes in rows and emit updates to the parent
watch(
  rows,
  (newRows) => {
    props.field.rows = newRows
    emit("update:field", props.field);
  },
  { deep: true }
);

function addRow(rowValues: any = null) {
  console.log("rows before add", rows.value)
  const row = {
    id: createUUID(),
    fields: []
  }
  props.field.columns.forEach((column: any) => {
    let rowField: Field = {
      id: createUUID(),
      name: column.name,
      type: column.type,
      modelId: column.modelId,
      value: ""
    }
    if (rowValues) { rowField.value = rowValues[column.name] || "" }
    if (column.type === "group_field") {
      rowField.fields = column.fields.map(field => ({
        ...field,
        value: rowValues ? rowValues[column.name + " / " + field.name] || "" : "",
        id: createUUID()
      }))
    }
    if (column.type === "choice") { rowField.choices = column.choices }
    if (column.type === "number" || column.type === "function") { rowField.format = column.format }
    if (column.type === "function") { rowField.formula = column.formula }
    row.fields.push(rowField)
  })
  rows.value.push(row)
  clearFilters()
  console.log("rows after add", rows.value)
}

function createUUID() {
  var dt = new Date().getTime()
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}
function removeRow(rowId) {
  console.log("rows before remove", rows.value)
  rows.value = rows.value.filter((row) => row.id !== rowId)
  // rowsUpdated.value = rowsUpdated.value + 1
  console.log("rows after remove", rows.value)
}

function openImportDialog() {
  const dialog = Dialog.create({
    component: ImportCsvDialog,
    componentProps: {
      tableColumns: columns.value,
    }
  }).onOk(({ csvData, columnMapping, choiceMappings }) => {
    console.log(csvData, columnMapping, choiceMappings)
    for (let row of csvData) {
      const mappedRow = {};
      for (const [csvColumn, tableColumn] of Object.entries(columnMapping)) {
        if (tableColumn) {
          mappedRow[tableColumn.name] =
            tableColumn.type === 'choice' ?
            choiceMappings[tableColumn.name][row[csvColumn]] :
            row[csvColumn];
        }
      }
      addRow(mappedRow)
    }
    console.log('Import complete');
    dialog.hide()
  })
}

function openExportDialog() {
  const dialog = Dialog.create({
    title: 'Export du tableau',
    message: `Voulez-vous exporter les ${filteredRows.value.length} lignes du tableau ?`,
    options: {
      type: 'radio',
      model: 'xlsx',
      items: [
        { label: 'Fichier Excel (.xlsx)', value: 'xlsx' },
        { label: 'Fichier CSV', value: 'csv' },
      ],
    },
    ok: {
      label: 'Confirmer',
      color: 'primary',
    },
    cancel: true,
  }).onOk((option) => {
    let blob
    const exportCols = columns.value.slice(1)
      .filter((col) => visibleColumns.value.includes(col.name) || col.type === 'file')
      .flatMap((col) => {
        if (col.type === 'group_field') {
          return col.fields.map((subColumn) => col.name + ' / ' + subColumn.name)
        }
        return [col.name]
      })
    // const exportRowSums = showSums.value ? exportCols.map(col => rowSums.value[col]) : []
    const exportRow = (row) => columns.value.slice(1)
      .filter((col) => visibleColumns.value.includes(col.name) || col.type === 'file')
      .flatMap((col) => {
        let rowField = row.fields.find((field) => field.name === col.name)
        if (col.type === 'group_field') {
          return rowField.fields.map((subField) => subField.value)
        }
        if (col.format) { return rowField.value + ' ' + col.format}
        return [rowField.value]
      })
    if (option === 'csv') {
      const delimiter = ';'
      const csvContent = [
        exportCols.join(delimiter),
        // exportRowSums.join(delimiter),
        ...filteredRows.value.map((row) => exportRow(row).join(delimiter)),
      ].join('\n');

      blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    } else if (option === 'xlsx') {
      const headerRow = exportCols
      const dataRows = filteredRows.value.map((row) => exportRow(row));

      const worksheetData = [headerRow, ...dataRows];
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

    }
    // Download the file
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', `${props.field.name}.${option}`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  })
}

</script>

<template>
  <div class="row q-col-gutter-x-md items-start">
    <p style="font-weight: 500;"><span style="text-decoration: underline;">Tableau</span>: {{ field.name }}</p>
    <q-btn flat dense round icon="more_vert" size="sm" class="q-ml-sm">
      <q-menu auto-close>
        <q-list style="min-width: 100px">
          <q-item clickable @click="openImportDialog">
            <q-item-section>Importer des données</q-item-section>
          </q-item>
          <q-item clickable @click="openExportDialog">
            <q-item-section>Exporter</q-item-section>
          </q-item>
        </q-list>
      </q-menu>
    </q-btn>
  </div>

  <q-table :columns="columns" :visible-columns="visibleColumns" :rows="filteredRows" row-key="id" flat bordered :rows-per-page-options=[0]>
    <template v-slot:top>
      <q-btn v-if="hasFilters" label="Effacer les filtres" icon="clear" color="primary" @click="clearFilters"/>
      <q-space />
      <q-select
        v-model="visibleColumns"
        multiple outlined dense options-dense
        :display-value="`Colonnes (${visibleColumns.length-1}/${columns.length-1})`"
        emit-value
        map-options
        :options="columns.filter((col) => col.label !== '')"
        option-value="name"
        style="min-width: 150px"
      >
      <template v-slot:option="scope">
        <q-item clickable :active="scope.selected" @click="scope.toggleOption(scope.opt)" >
          <q-checkbox v-model="scope.selected" size="sm" label=" " @click="scope.toggleOption(scope.opt)"/>
          <q-item-section>
            {{ scope.opt.label }}
          </q-item-section>
        </q-item>
      </template>
    </q-select>
    </template>
    <template v-slot:header-cell="props">
      <q-th :props="props">
        <span>{{ props.col.label }}</span>
        <q-btn-dropdown v-if="props.col.name !== 'actions'" dense flat round size="sm" dropdown-icon="filter_list" @click.stop
          :color="filters[props.col.name]?.length > 0 ? 'primary' : undefined">
          <q-option-group
            v-model="filters[props.col.name]"
            :options="uniqueValues[props.col.name].map(value => ({label: value, value}))"
            type="checkbox"
            class="q-pa-sm q-pr-lg"
          />
      </q-btn-dropdown>
      </q-th>
    </template>
    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td key="actions" :props="props">
          <q-btn v-if="props.rowIndex === filteredRows.length - 1" outline rounded class="q-mr-sm" padding="xs" color="primary"
            icon="add" @click="addRow()"></q-btn>
          <q-btn outline rounded color="negative" padding="xs" icon="delete" @click="removeRow(props.row.id)"></q-btn>
        </q-td>
        <q-td :props="props" :key="field.name" v-for="field in props.row.fields">
          <TextField v-if="field.type === 'text'" :field="field" :showLabel="false" />
          <WysiwygTextField v-if="field.type === 'wysiwyg_text'" :field="field" :showLabel="false" />
          <NumberField v-if="field.type === 'number'" :field="field" :showLabel="false" />
          <DateField v-if="field.type === 'date'" :field="field" :showLabel="false" />
          <div v-if="field.type === 'group_field'" class="row q-col-gutter-xs flex-wrap">
            <!-- <q-input v-for="subfield in field.fields"  :key="subfield.id"
              v-model="subfield.value" :label="showLabel ? subfield.name : undefined" debounce="1000"
              class="col-auto" style="flex: 1 1 calc(20% - 8px); min-width: 100px" /> -->
            <GroupField :field="field" :showLabel="false" />
          </div>
          <ChoiceField v-if="field.type === 'choice'" :field="field" :showLabel="false" />
          <MultipleChoice v-if="field.type === 'multi_choice'" :field="field" :showLabel="false" />
          <FunctionField v-if="field.type === 'function'" :field="field" :showLabel="false" :otherFields="props.row.fields" />
          <FileField v-if="field.type === 'file'" :field="field" :row="props.row" :showLabel="false" />
        </q-td>
      </q-tr>
    </template>
    <!-- <template #top v-if="rows.length">
      <q-btn icon="add" color="primary" class="q-mr-md" style="margin: auto" @click="addRow()">Ajouter
        une donnée</q-btn>
    </template> -->
    <template #no-data>
      <div class="row justify-center full-width">
        <q-btn icon="add" color="primary" @click="addRow()">Ajouter une donnée</q-btn>
      </div>
    </template>
    <template v-if="showSums" v-slot:top-row="props">
      <q-tr style="background-color: #f5f5f5">
        <q-td v-for="col in props.cols" :key="col.name">
          <template v-if="col.name !== 'actions'">
            {{rowSums[col.name]}}
          </template>
          <template v-else>
              Totaux
          </template>
        </q-td>
      </q-tr>
    </template>

  </q-table>

</template>

<style lang="scss" scoped>
.table {
  margin-top: 10px;
}

h3 {
  font-size: medium;
}
</style>
