<script lang="ts" setup>
import { ref } from 'vue'
import { useDialogPluginComponent } from 'quasar'
import { Question } from '@/models/survey/Question'
import BaseDialog from '@/components/base/BaseDialog.vue'
import { useApi } from '@/store/useApi'
import UserApi from '@/services/api/core/UserApi'
import { DatabaseObject } from '@/models/database/Database'

interface Props {
  // rows: Array<any> | undefined
  // columns: Array<any>
  databaseObject?: any
  type: string
  modelId: string
  title: any
  listNodeIds?: Array<string>
  question: Question
  multiSelect: boolean
}
const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits([...useDialogPluginComponent.emits])
const userApi: UserApi = useApi()

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const selected = ref([])
const rows = ref([])
const columns = ref([])

function beforeShow() {
  if (props.type == 'list') {
    rows.value = getListRows(props.databaseObject, props.modelId)
    columns.value = [{ name: 'Objet', label: 'Objet', field: 'name', align: 'left', sortable: true }]
  } else if (props.type == 'array') {
    const result = getArrayRows(props.databaseObject.definitionJson, props.modelId, props.listNodeIds)
    rows.value = result.rows
    columns.value = result.columns
  }
}

function getListRows(databaseObject: DatabaseObject, listModelId) {
  // Search for the right lists in a databaseObject
  const rows = []
  const children = databaseObject.definitionJson.children
  for (const child of children) {
    if (child.type === 'LIST' && child.modelId === listModelId && child.children) {
      console.log('Found children', child.children)
      rows.push(...child.children)
    }
  }
  return rows
}

function getArrayRows(node: Node, arrayModelId, listNodeIds) {
  // Recursively search for the right arrays in an databaseObject
  // Returns {rows, columns}
  for (const field of node.fields) {
    if (field.type === 'array' && field.modelId === arrayModelId && field.rows) {
      console.log('Found rows', field.rows)
      // Reformat rows
      let rows = field.rows.map((row) => {
        let newRow = { id: row.id }
        row.fields.forEach(f => newRow[f.name] = f.value)
        return newRow
      })
      // Extract column definitions
      // let columns = field.rows[0]?.fields.map(f => ({ name: f.name, label: f.name, field: f.name, align: 'left', sortable: true }))
      let columns = field.columns?.map(col => ({ name: col.name, label: col.name, field: col.name, modelId: col.modelId, align: 'left', sortable: true }))
      return {rows, columns, field}
    }
  }
  if (node.children) {
    let recursiveResult
    let childrenNodes = node.children
    // If the node is a list, only search in the right list node according to listNodeIds
    if (node.type == 'LIST' && listNodeIds != undefined) {
      childrenNodes = childrenNodes.filter(child => listNodeIds.includes(child.id))
    }
    for (const childNode of childrenNodes) {
      recursiveResult = getArrayRows(childNode, arrayModelId, listNodeIds)
      if (recursiveResult) {
        return recursiveResult
      }
    }
  }
  return null
}

function onRowClicked(row) {
  console.log("selected item", row.id)
  emit('ok', [row.id])
}

function refreshObject() {
  userApi.getDatabaseObject(props.databaseObject.id).then((response) => {
    Object.assign(props.databaseObject, response) // response.data
    console.log("refreshed object", props.databaseObject)
    beforeShow()
  })

}

// TODO: add ability to "create" , just emit back an empty array and let ObjectSelectionFormv2 create.
// function onNewClick() {
//   emit('ok', [])
// }

function addAnswer() {
  console.log("selected items", selected.value)
  emit('ok', selected.value.map(item => item.id))
}
</script>
<template>
  <q-dialog ref="dialogRef" @before-show="beforeShow">
    <BaseDialog :title="title" @on-dialog-cancel="onDialogCancel()" @hide="onDialogHide()">
      <template #body>
        <div class="row justify-between">
          <q-btn color="primary" icon="refresh" @click="refreshObject" class="q-mb-md"/>
          <!-- <q-btn color="primary" label="Nouveau" @click="onNewClick()" style="width: 100px" class="q-mb-md"/> -->

          <div v-if="databaseObject">
            <!-- Link to the database object -->
            <a :href="`/clients/${databaseObject.clientId}/database/${databaseObject.id}`"
            target="_blank" rel="noopener noreferrer">
              <q-icon name="open_in_new" size="xs" /> {{ databaseObject.definitionJson.name }}
              <q-tooltip anchor="bottom right" self="top middle">
                Ouvrir les dossiers permanents
              </q-tooltip>
            </a>
          </div>
        </div>
        <q-table v-if="multiSelect"
        flat bordered row-key="id" :rows="rows" :columns="columns" selection="multiple" v-model:selected="selected"
        style="width: 100%"/>
        <q-table v-else
        flat bordered row-key="id" :rows="rows" :columns="columns"
        style="width: 100%" @row-click="(evt, row, index) => onRowClicked(row)"/>
      </template>
      <template #actions><q-btn flat color="primary" label="Ajouter" @click="addAnswer()"></q-btn> </template>
    </BaseDialog>
  </q-dialog>
</template>
<style lang="scss" scoped></style>
