<script setup lang="ts">
import { ref, computed, watch, onMounted, nextTick } from 'vue';
import nunjucks from 'nunjucks';
import { Page } from '@/models/survey/Page'
import { useApi } from '@/store/useAppStore'
import { useRoute } from 'vue-router'
import UserApi from '@/services/api/core/UserApi'

// Import props
const props = defineProps<{
    surveyPages: Array<Page>
}>();

const route = useRoute()
const userApi: UserApi = useApi()
nunjucks.configure({ autoescape: false });

// State variables
const template = ref('');
const renderedTemplate = ref('');
// Define the mapping of variables to their values
const variableMapping = computed(() => {
    return props.surveyPages.reduce((acc, page) => {
        page.questions.forEach(question => {
            const key = `${question.type}_${question.id}`;
            if (['text', 'number', 'date', 'choice', 'textformat'].includes(question.type)) {
                acc[key] = question.answer.value || '___'; // Use '___' if the answer is not set
            } else if (question.type === 'bool') {
                acc[key] = question.answer.value ? 1 : 0;
            } else if (question.type === 'object') {
                acc[key] = {options: {}}
                for (const option of question.options) {
                    // eg object_902768.options["Zn37YLwu"]
                    acc[key].options[option.id.toLowerCase()] = option.answer.value || '___'; // Use '___' if the answer is not set
                }
            } else if (['multi', 'list'].includes(question.type)) {
                acc[key] = question.answer.value;
                // console.log(question.type, question.id, acc[key])
            } else if (['array', 'multiarray'].includes(question.type)) {
                acc[key] = [];
                for (const element of question.answer.value) {
                    // if element has fields
                    if (element.fields) {
                        acc[key].push({
                            '__object_name__': element.type,
                            ...Object.fromEntries(element.fields.map((field) => [field.id.toLowerCase(), field.value])),
                        });
                        // acc[key].push(element); // works for array ?
                    } else {
                        // else push direclty a dictionnary with key element.id and value element.value
                        console.log('issue with array without element structure', element)
                        let lowercasedDict = Object.fromEntries(Object.entries(element).map(([key, value]) => [key.toLowerCase(), value]));
                        acc[key].push(lowercasedDict)
                    }
                }
                // console.log(question.type, question.id, acc[key])

            } else { }
        });
        return acc;
    }, {});
}) // the accumulator starts as an empty object {}

// Watch for changes in variableMapping to update the template and focus on the newly filled field using the right key
// Text -> var-text_123
// Object -> var-objet_123-aBc (aBc is the option that changed)
// Bool -> var-bool_123-0 (if unchecked)
// Choice/Multi -> var-multi_123-eDf (eDf is the choice that was made)
watch(variableMapping, (newVal, oldVal) => {
    for (const key in newVal) {
        // Get the variable that changed (within an object if necessary)
        if (key.startsWith('object_')) {
            for (const subKey in newVal[key].options) {
                if (newVal[key].options[subKey] !== oldVal[key].options[subKey]) {
                    const variableKey = `${key}.${subKey}`
                    console.log('Variable changed:', variableKey);
                    updateRenderedTemplate(variableKey);
                    return; // Only focus on the first changed variable
                }
            }
        }
        else if (key.startsWith('multi_') || key.startsWith('list_')) {
            let changedElement = findChangedElement(oldVal[key], newVal[key])
            if (changedElement) {
                const variableKey = `${key}-${changedElement.toLowerCase().replace(/\W+/g, '')}`
                console.log('Variable changed:', variableKey);
                updateRenderedTemplate(variableKey);
                return; // Only focus on the first changed variable
            }
        }
        else if (key.startsWith('multiarray_') || key.startsWith('array_')) {
            if (JSON.stringify(newVal[key]) !== JSON.stringify(oldVal[key])) {
                const variableKey = `${key}`
                console.log('Variable changed:', variableKey);
                updateRenderedTemplate(variableKey);
                return; // Only focus on the first changed variable
            }
        }
        else if (key.startsWith('choice_') || key.startsWith('bool_')) {
            if (newVal[key] !== oldVal[key]) {
                let value = newVal[key]
                if (key.startsWith('choice_')) { value = value.toLowerCase().replace(/\W+/g, '') }
                const variableKey = `${key}-${value}`
                console.log('Variable changed:', variableKey);
                updateRenderedTemplate(variableKey);
                return; // Only focus on the first changed variable
            }
        }
        else if (newVal[key] !== oldVal[key]) {
            console.log('Variable changed:', key);
            updateRenderedTemplate(key);
            return; // Only focus on the first changed variable
        }
    }
}, { deep: true }
);

// Function to update the rendered template with focus on the specific element
const updateRenderedTemplate = (focus: string | null) => {
    // console.log("variableMapping", variableMapping)
    if (template.value) {
        let renderedHtml = injectIdentifiers(template.value);
        // console.log("renderedHtml after injectIdentifiers", renderedHtml)

        if (focus) {
            const sanitizedKey = `var-${focus.replace(/[\[\]\.\"]/g, '-')}`;
            // console.log('sanitizedKey', sanitizedKey)
            // Add the spans around the no-code elements whose value changed
            renderedHtml = addHighlightSpans(renderedHtml, sanitizedKey)
        }

        // Remove any html from within {{}} tags
        renderedHtml = renderedHtml.replace(/(\{\{[^}]*?)(?:<[^>]+>)+(.*?\}\})/g, (match, p1, p2) => {
            return p1 + p2;
        })
        // Fill the values with nunjucks
        renderedHtml = nunjucks.renderString(renderedHtml, variableMapping.value);
        // Correct the html
        renderedHtml = correctHtml(renderedHtml)
        renderedHtml = capitalizeAndNumerateTitles(renderedHtml)
        // console.log("renderedHtml after nunjucks and correction", renderedHtml)

        // Add the highlight class to each html elements (uses DOM parser which corrects the html)
        if (focus) {
            renderedHtml = addHighlightRangy(renderedHtml)
            // console.log("renderedHtml after rangy", renderedHtml)
        }

        // Render the html
        renderedTemplate.value = renderedHtml;

        // Scroll to the focused element
        if (focus) {
            const sanitizedKey = `var-${focus.replace(/[\[\]\.\"]/g, '-')}`;
            const element = document.getElementById(sanitizedKey);
            // console.log('element', element?.outerHTML)
            if (element) {
                element.scrollIntoView({ block: 'center' , behavior: "smooth"});
            }
        }
    }
};

/**
* Function to inject unique identifiers into the rendered HTML, in order to highlight elements
* It will add <span> elements with ids around no-code elements.
* {{ text_123 }} -> <span id="var-text_123" class="variable">{{ var_123 }}</span>
* {{ object_123.options["aBc"]}} -> <span id="var-object_123-aBc" class="variable">var_123.options["aBc"]</span>
* {% if bool_123 == 1 %} -> <span id="var-bool_123" class="variable">{% if bool_123 == 1 %}
*
*/
function injectIdentifiers(html) {
    let updatedHtml = html;
    let countReplaced = { variable: 0, if: 0, endif: 0, for: 0, endfor: 0, forline: 0 };

    // Replace forline with for and endfor
    updatedHtml = updatedHtml.replace(/forline([\w\W]*?)<\/table>/g, (match, text) => {
        countReplaced.forline += 1;
        return 'for' + text + '{% endfor %}</table>'
    })

    // Pattern to match both simple and nested variables (bool_123 & object_123.options["aBc"] & internal["aBc"])
    const variablePattern = /{{\s*([a-zA-Z0-9_]+)(?:\.[a-zA-Z0-9_]+)?\[?"?([a-zA-Z0-9_]+)?"?\]?\s*}}/g;
    updatedHtml = updatedHtml.replace(variablePattern, (match, variableName, optionName) => {
        countReplaced.variable += 1;
        // Create a unique ID from the variable name
        let uniqueId
        if (optionName) {
            uniqueId = `var-${variableName}-${optionName.toLowerCase()}`;
        } else {
            uniqueId = `var-${variableName}`;
        }
        return `<span id="${uniqueId}" class="variable">${match.toLowerCase()}</span>`;
    });
    updatedHtml = updatedHtml.replace(/.options\["\w+"\]/g, option => option.toLowerCase())

    // Pattern to match if and endif (check https://regexr.com/)
    // to match {% if bool_123 == 1 %} and {% if choice_123 == "Option 1" %}
    // and {% if object_123.options["aBc"] == "Option 1" %}
    // and {% if internal["__object_name__"] == "Personne physique" %} (to open this span although we don't use it)
    const ifPattern = /{%\s+if\s+([a-zA-Z0-9_]+)(?:\.[a-zA-Z0-9_]+)?(?:\["([a-zA-Z0-9_]+)"\])?\s*==\s*(?:"([^"]+)"|(\d+))\s*%}/g;
    updatedHtml = updatedHtml.replace(ifPattern, (match, variableName, optionName, stringValue, numericValue) => {
        countReplaced.if += 1
        let uniqueId
        if (optionName) {
            uniqueId = `var-${variableName}-${optionName.toLowerCase()}-${numericValue || stringValue.toLowerCase().replace(/\W+/g, '')}`;
        } else {
            uniqueId = `var-${variableName}-${numericValue || stringValue.toLowerCase().replace(/\W+/g, '')}`;
        }
        return `<span id="${uniqueId}" class="conditional">${match}`;
    });
    // to match {% if "Option 1" in multi_123 %} and {% if "Option 1" in object_123["abc"] %}
    const ifMultiPattern = /{%\s+if\s*"([^"]+)"\s+in\s+([a-zA-Z0-9_]+)(?:\.[a-zA-Z0-9_]+)?(?:\["([a-zA-Z0-9_]+)"])?\s*%}/g;
    updatedHtml = updatedHtml.replace(ifMultiPattern, (match, stringValue, variableName, optionName) => {
        countReplaced.if += 1
        let uniqueId
        if (optionName) {
            uniqueId = `var-${variableName}-${optionName.toLowerCase()}-${stringValue.toLowerCase().replace(/\W+/g, '')}`;
        } else {
            uniqueId = `var-${variableName}-${stringValue.toLowerCase().replace(/\W+/g, '')}`;
        }
        return `<span id="${uniqueId}" class="conditional">${match}`;
    });
    const endifPattern = /{%\s+endif\s+%}/g;
    updatedHtml = updatedHtml.replace(endifPattern, (match) => {
        countReplaced.endif += 1
        return `${match}</span>`;
    });

    // Match for blocks
    const forPattern = /{%\s+for\s+([a-zA-Z0-9_]+)\s+in\s+([a-zA-Z0-9_]+(?:\.[a-zA-Z0-9_]+|\["[a-zA-Z0-9_]+"])*?)\s*%}/g;
    updatedHtml = updatedHtml.replace(forPattern, (match, loopVariable, iterable) => {
        countReplaced.for += 1
        const uniqueId = `var-${iterable.replace(/[\[\]\.\"]/g, '-')}`;
        return `<span id="${uniqueId}" class="loop">${match}`;
    });
    const endforPattern = /{%\s+endfor\s+%}/g;
    updatedHtml = updatedHtml.replace(endforPattern, (match) => {
        countReplaced.endfor += 1
        return `${match}</span>`;
  });


    // console.log("Added identifiers")
    // console.log("countReplaced", countReplaced)

    return updatedHtml;
};


function correctHtml(renderedHtml) {
    // Try closing lists on new titles or articles
    renderedHtml = renderedHtml.replace(/<ol>([\w\W]*?)(<p class='numbered_article|<h)/g, (match, content, title) => {
        // console.log("Correcting list", !content.includes('</ol>'))
        if (!content.includes('</ol>')) {
            return `<ol>${content}</ol>${title}`;
        }
        return `<ol>${content}${title}`;
    });

    // Try removing extra <ul>
    const ulCount = (renderedHtml.match(/<ul>/g) || []).length;
    const culCount = (renderedHtml.match(/<\/ul>/g) || []).length;
    // console.log("Correcting ul", ulCount, culCount)
    if (ulCount > culCount) {
        renderedHtml = renderedHtml.replace(/<ul>(.*?)<\/ul>/g, (match, group) => {
            return `<ul>${group.replace(/<ul>/g, '')}</ul>`;
        });
    }
    // Try removing extra <ol>
    const olCount = (renderedHtml.match(/<ol>/g) || []).length;
    const colCount = (renderedHtml.match(/<\/ol>/g) || []).length;
    // console.log("Correcting ol", olCount, colCount)
    if (olCount > colCount) {
        renderedHtml = renderedHtml.replace(/<ol>(.*?)<\/ol>/g, (match, group) => {
            return `<ol>${group.replace(/<ol>/g, '')}</ol>`;
        });
    }
    // Try closing empty list items
    renderedHtml = renderedHtml.replace(/<li>(<[\w\s]*>)*<\/li>/g, '')
    return renderedHtml;
}

// Find the closing span associated with the key, and give it an id
function addHighlightSpans(html, sanitizedKey) {
        const startTag = `<span id="${sanitizedKey}"`;
        const endTag = '</span>';
        let spanCount = 0;

        // Find the position of the start tag
        const startIdx = html.indexOf(startTag);

        if (startIdx !== -1) {
        // Find the position where the highlight should end
        const startSpanEnd = html.indexOf('>', startIdx) + 1;
        let searchIdx = startSpanEnd;
        let endIdx = -1;

        while (searchIdx < html.length) {
            const nextOpenTag = html.indexOf('<span', searchIdx);
            const nextCloseTag = html.indexOf(endTag, searchIdx);

            if (nextCloseTag === -1) break; // No more closing tags, exit loop

            if (nextOpenTag !== -1 && nextOpenTag < nextCloseTag) {
            // We found another opening <span> tag before the next closing </span>
            spanCount++;
            searchIdx = nextOpenTag + 5; // Move search index past this opening tag
            } else {
            // We found a closing </span> tag
            if (spanCount === 0) {
                // This is the corresponding closing tag
                endIdx = nextCloseTag + endTag.length;
                break;
            } else {
                // This closing tag closes a nested <span>, decrement the count
                spanCount--;
                searchIdx = nextCloseTag + endTag.length; // Move search index past this closing tag
            }
            }
        }
        // console.log("Found startIdx", startIdx, "startSpanEnd", startSpanEnd, "endIdx", endIdx)

        // If we found a corresponding closing </span> tag, put an identifier
        if (endIdx !== -1) {
            // Add identifier
            html = html.slice(0, startIdx) + '<span id="start-highlight"></span>' + html.slice(startIdx);
            html = html.slice(0, endIdx + 34) + '<span id="end-highlight"></span>' + html.slice(endIdx + 34);

            // Replace the original raw HTML with the processed one
            return html;
        } else {
            // If no closing tag was found, fallback to the entire remainder
            return html;
        }
        } else {
            return html; // No highlighting possible, just render as is
        }
    }

// Add class highlight to every element between start and end
function addHighlightRangy(html) {
    // Set the start and end points of the range
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    var startSpan = doc.getElementById('start-highlight');
    var endSpan = doc.getElementById('end-highlight');
    if (startSpan && endSpan) {
        // Create a flag to know when to start applying the highlight class
        let inRange = false;
        // Traverse the document tree in order, using TreeWalker to process all nodes
        const walker = doc.createTreeWalker(doc.body, NodeFilter.SHOW_ELEMENT, null);
        let currentNode = walker.currentNode;

        while (currentNode) {
            // If we reached the start span, set the flag to start highlighting
            if (currentNode === startSpan) {
                inRange = true;
            }
            // If we're in the range, apply the highlight class to the element
            if (inRange && currentNode.nodeType === 1 && currentNode.textContent.trim()) {
                currentNode.classList.add('highlight');
            }
            // If we reach the end span, stop the traversal
            if (currentNode === endSpan) {
                break;
            }
            // Move to the next node in the document order
            currentNode = walker.nextNode();
        }
    }
    return doc.body.innerHTML;
}

function capitalizeAndNumerateTitles(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    // Titles
    const titles = doc.querySelectorAll("h1, h2, h3");
    let count1 = 0, count2 = 0, count3 = 0;
    titles.forEach(title => {
        let titleNumber = title.tagName[1];
        if (titleNumber === '1') {
            count1++;
            count2 = 0;
            count3 = 0;
            title.textContent = `${count1}. ${title.textContent}`;
        } else if (titleNumber === '2') {
            count2++;
            count3 = 0;
            title.textContent = `${count1}.${count2}. ${title.textContent}`;
        } else if (titleNumber === '3') {
            count3++;
            title.textContent = `${count1}.${count2}.${count3}. ${title.textContent}`;
        }
    });
    // Articles
    const articles = doc.querySelectorAll("p[class^='numbered_article']");
    count1 = 0, count2 = 0, count3 = 0;
    articles.forEach(article => {
        if (article.classList.contains('numbered_article1')) {
            // For level 1 articles, increment count1 and reset count2 and count3
            count1++;
            count2 = 0;
            count3 = 0;
            article.textContent = `ARTICLE ${count1}. ${article.textContent}`;
        } else if (article.classList.contains('numbered_article2')) {
            // For level 2 articles, increment count2 and reset count3
            count2++;
            count3 = 0;
            article.textContent = `ARTICLE ${count1}.${count2}. ${article.textContent}`;
        } else if (article.classList.contains('numbered_article3')) {
            // For level 3 articles, just increment count3
            count3++;
            article.textContent = `ARTICLE ${count1}.${count2}.${count3}. ${article.textContent}`;
        }
    });
    const toCapitalize = doc.querySelectorAll("h1, h2, h3, h4, h5, h6, p[class^='numbered_article']");
    toCapitalize.forEach(element => element.textContent = element.textContent?.toUpperCase())
    // Resolutions
    const resolutions = doc.querySelectorAll("p[class='numbered_resolution']")
    resolutions.forEach((resolution, index) => {
        const list = ["DEUXIÈME", "TROISIÈME", "QUATRIÈME", "CINQUIÈME", "SIXIÈME", "SEPTIÈME", "HUITIÈME", "NEUVIÈME", "DIXIÈME", "ONZIÈME", "DOUZIÈME", "TREIZIÈME", "QUATORZIÈME", "QUINZIÈME", "SEIZIÈME", "DIX-SEPTIÈME", "DIX-HUITIÈME", "DIX-NEUVIÈME", "VINGTIÈME", "VINGT-ET-UNIÈME", "VINGT-DEUXIÈME", "VINGT-TROISIÈME", "VINGT-QUATRIÈME", "VINGT-CINQUIÈME", "VINGT-SIXIÈME", "VINGT-SEPTIÈME", "VINGT-HUITIÈME", "VINGT-NEUVIÈME", "TRENTIÈME", "TRENTE-ET-UNIÈME", "TRENTE-DEUXIÈME", "TRENTE-TROISIÈME", "TRENTE-QUATRIÈME", "TRENTE-CINQUIÈME", "TRENTE-SIXIÈME", "TRENTE-SEPTIÈME", "TRENTE-HUITIÈME", "TRENTE-NEUVIÈME", "QUARANTIÈME", "QUARANTE-ET-UNIÈME", "QUARANTE-DEUXIÈME", "QUARANTE-TROISIÈME", "QUARANTE-QUATRIÈME", "QUARANTE-CINQUIÈME", "QUARANTE-SIXIÈME", "QUARANTE-SEPTIÈME", "QUARANTE-HUITIÈME", "QUARANTE-NEUVIÈME", "CINQUANTIÈME", "CINQUANTE-ET-UNIÈME", "CINQUANTE-DEUXIÈME", "CINQUANTE-TROISIÈME", "CINQUANTE-QUATRIÈME", "CINQUANTE-CINQUIÈME", "CINQUANTE-SIXIÈME", "CINQUANTE-SEPTIÈME", "CINQUANTE-HUITIÈME", "CINQUANTE-NEUVIÈME", "SOIXANTIÈME"]
        resolution.textContent = `${list[index]} ${resolution.textContent}`;
    });
    return doc.body.innerHTML;
}

function findChangedElement(oldArray, newArray, objectArray=false) {
    // Check for added element
    if (newArray.length > oldArray.length) {
        return newArray.find(element => !oldArray.includes(element));
    }
    // Check for removed element
    else if (newArray.length < oldArray.length) {
        return oldArray.find(element => !newArray.includes(element));
    }
    return null;
}

// Fetch the template from the API when the component mounts
const fetchTemplate = async () => {
    try {
        const response = await userApi.getDocumentTemplateHtml(route.params.documentId);
        // console.log('Response',response)
        template.value = response.html

    } catch (error) {
      console.error('Error fetching template:', error);
    }

    try {
        updateRenderedTemplate(null);
    } catch (error) {
      console.error('Error rendering template:', error);
    }
  };

onMounted(fetchTemplate);
</script>

<template>
  <div v-if="renderedTemplate" v-html="renderedTemplate"></div>
  <div v-else><q-inner-loading showing color="primary" /></div>
</template>


<style scoped>
/* Add styles to make the preview look similar to the final document */
div {
    font-family: Arial, sans-serif;
    line-height: 1.5;margin:20px;
}

:deep(h1) {
    font-size: 20px;
    line-height: normal;
}

:deep(h2) {
    font-size: 16px;
    line-height: normal;
}

:deep(h3, h4, h5, h6, h7) {
    font-size: 14px;
    line-height: normal;
}

:deep(.highlight) {
  background-color: yellow;
  transition: background-color 0.5s ease;
}

.variable, .conditional, .loop {
  display: inline-block;
}
</style>
