<script lang="ts" setup>
import { computed, onMounted } from "vue";
import { Dialog, useDialogPluginComponent } from 'quasar'
import UserApi from "@/services/api/core/UserApi";
import { useApi } from "@/store/useApi";
import { Question } from '@/models/survey/Question'
import { findMatchingFields, updateMatchingFields } from "@/components/common/database/utils"

interface Props {
  question: Question
  questionOptions: any[]
  valueDict: any
  isEdit?: boolean
  databaseObjectId?: string,
  listNodeId?: any,
  arrayRowId?: any,
  questionMappings: any[],
}
const props = withDefaults(defineProps<Props>(), {
  isEdit: false,
})

const emit = defineEmits([...useDialogPluginComponent.emits, 'addClick', 'editClick'])
const userApi: UserApi = useApi()
const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const booleanSelectValues = [
  {
    label: 'Oui',
    value: true,
  },
  {
    label: 'Non',
    value: false,
  },
]

let title
if (props.isEdit) {
  title = 'Modifier une donnée'
} else {
  title = 'Ajouter une donnée'
}

let objectFields, types, databaseObject
let matchingFields
const hasConflict = computed(() => {
  return Object.values(props.valueDict).some(field => typeof field === 'object' && field && 'survey' in field)
})

async function beforeShow() {
  if (props.databaseObjectId) {
    databaseObject = await userApi.getDatabaseObject(props.databaseObjectId)
    matchingFields = findMatchingFields(databaseObject.definitionJson, props.listNodeId, props.arrayRowId, props.questionMappings)
    objectFields = Object.fromEntries(matchingFields.map(field => [field.variableFieldName, field.value]))
    types = Object.fromEntries(matchingFields.map(field => [field.variableFieldName, field.type]))
  } else { // case of a manual entry in a question "array"
    objectFields = {}
  }
  console.log('objectFields', objectFields)

  // Build the current answer based on either the object fields or the question answers
  props.questionOptions.forEach(option => {
    if (!props.valueDict[option.id]) {
      props.valueDict[option.id] = objectFields[option.label] ? objectFields[option.label] : ''
    } else if (!objectFields[option.label]) {
      props.valueDict[option.id] = props.valueDict[option.id]
    } else if (objectFields[option.label] == props.valueDict[option.id]) {
      props.valueDict[option.id] = props.valueDict[option.id]
    } else {
      // Two distinct values
      props.valueDict[option.id] = {'survey': props.valueDict[option.id], 'object': objectFields[option.label]}
    }
  })
}

// Helper functions
function getInputType(field) {
  // Map field types to input types (e.g., text, number, date)
  const typeMapping = {
    string: "text",
    number: "number",
    date: "date",
  };
  return typeMapping[field.type] || "text";
}

// Update object and answer
async function onConfirm() {
  if (databaseObject) {
    // Update object
    let valueByLabel = {}
    props.questionOptions.forEach(option => {
      valueByLabel[option.label] = props.valueDict[option.id]
    })
    updateMatchingFields(databaseObject.definitionJson, props.listNodeId, props.arrayRowId, props.questionMappings, valueByLabel)
    console.log('definitionJson', databaseObject.definitionJson)
    await userApi.updateDatabaseObject(databaseObject.id, databaseObject.definitionJson);
  }

  emit("ok");
}

function deleteLink() {
  // Delete the link with the Dossier permanent and close dialog
  Dialog.create({
    title: 'Supprimer',
    message: 'Etes-vous sur de vouloir supprimer le lien avec le dossier permanent ?',
    style: 'white-space: pre-wrap;',
    cancel: true,
  }).onOk(async () => {
    emit('ok',  {deleteLink: true})
  })
}

function getUrl() {
  let url = `/clients/${databaseObject.clientId}/database/${databaseObject.id}`
  if (props.listNodeId) return url + `#item-${props.listNodeId}`
  return url + `#item-${matchingFields?.[0].pageId}`
}
</script>

<template>
  <q-dialog ref="dialogRef" @before-show="beforeShow()">
    <q-card style="width: 700px; max-width: 80vw; max-height: 80vh">
      <q-card-section class="row items-center justify-between">
        <div class="text-h6">{{question.label}}</div>
        <!-- Link to the database object -->
        <div v-if="databaseObject" >
          <q-icon name="close" size="xs" class="cursor-pointer q-mr-sm unlink-button" @click="deleteLink" />
          <a :href="getUrl()" target="_blank" rel="noopener noreferrer">
            <q-icon name="open_in_new" size="xs" /> {{ databaseObject.definitionJson.name }}
            <q-tooltip anchor="bottom right" self="top middle">
              Ouvrir les dossiers permanents
            </q-tooltip>
          </a>
        </div>
      </q-card-section>

      <q-card-section v-if="databaseObject">
        <div v-if="hasConflict" >
          <q-icon name="warning" size="xs" color="warning" style="vertical-align: middle;"/>
           Il y a des différences entre la <span class="text-green">réponse actuelle</span>
          et le <span class="text-blue">dossier permanent.</span>
            <br>Veuillez choisir les informations appropriées. Le dossier permanent sera également mis à jour.
        </div>
        <div v-else>
          Les informations suivantes seront mises à jour dans le questionnaire et dans le dossier permanent.
        </div>
      </q-card-section>

      <q-card-section>
        <div v-for="option in questionOptions" :key="option.id" class="q-mb-md">
          <!-- If the answer has two conflicting values, propose resolution -->
          <div v-if="typeof valueDict[option.id] == 'object' && 'survey' in valueDict[option.id]" class="conflict-values">
            {{ option.label }}  <q-icon name="warning" size="xs" color="warning" />

            <div class="q-mt-sm">
              <q-btn
                v-for="(value, source) in valueDict[option.id]"
                :key="source"
                :color="source === 'survey' ? 'green' : 'blue'"
                @click="valueDict[option.id] = value"
                class="q-mr-sm"
                :disable="source === 'survey' && types[option.label] === 'function'"
            >
                {{ value }}
                <q-tooltip anchor="bottom right" self="top middle" :delay="500" v-if="source === 'survey' && types[option.label] === 'function'">
                  Ce champs étant issu d'un calcul, la valeur du dossier permanent ne peut pas être modifiée via le questionnaire.
                  Veuillez la modifier directement dans le dossier permanent.
                </q-tooltip>
                <q-tooltip anchor="bottom right" self="top middle" :delay="500" v-else-if="source === 'survey'">
                  Choisir la valeur du dossier permanent.
                </q-tooltip>
                <q-tooltip anchor="bottom right" self="top middle" :delay="500" v-else-if="source === 'object'">
                  Choisir la valeur du dossier permanent.
                </q-tooltip>
              </q-btn>
            </div>
          </div>
          <!-- Else, show the input -->
          <div v-else>
            <q-input v-if="option.type === 'text' || option.type === 'number' || option.type === 'date'"
            v-model="valueDict[option.id]"
            :label="option.label"
            :type="getInputType(option)"
            dense outlined
            />
            <!-- <div v-if="option.type === 'bool'" class="column">
              <BaseSelect clearable v-model="valueDict[option.id]" :options="booleanSelectValues" emit-value map-options>
              </BaseSelect>
            </div>
            <div v-if="option.type === 'date'">
              <BaseDatePicker v-model="valueDict[option.id]" :label="option.label"> </BaseDatePicker>
            </div> -->
          </div>
        </div>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="Annuler" color="negative" @click="emit('hide')" />
        <q-btn flat label="Confirmer" color="primary" @click="onConfirm" :disable="hasConflict" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<style lang="scss" scoped>
.conflict-values {
  padding: 8px 12px; /* Same padding as q-input */
  border: 1px solid lightgray; /* Use Quasar primary color */
  border-radius: 4px; /* Matches q-input rounded corners */
  outline: none; /* Remove browser default focus outline */
}
/* Add hover effect similar to q-input */
.conflict-values:hover {
  border-color: var(--q-color-primary-light);
}
/* Add focus effect similar to q-input */
.conflict-values:focus-within {
  border-color: var(--q-color-primary);
  box-shadow: 0 0 4px var(--q-color-primary-light);
}
.unlink-button {
  border-radius: 40%;
  background-color: $red-6;
  color: white
}
</style>
