<script setup>
import { ref, computed } from 'vue';
import { useDialogPluginComponent } from 'quasar'
import * as XLSX from 'xlsx/xlsx.mjs';

const props = defineProps({
  tableColumns: Array, // Table column definitions with types and names
});

const emit = defineEmits([...useDialogPluginComponent.emits])
const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const csvData = ref([]);
const step = ref(1);
const columnMapping = ref({});
const tableColumnOptions = computed(() => props.tableColumns.slice(1).flatMap(col => (col.type === 'group_field' ? col.fields.map(subCol => ({ name: col.name + ' / ' + subCol.name, type: subCol.type })) : { name: col.name, type: col.type })));
const choiceMappings = computed(() => {
  // For each choice column, map each of its value
  return Object.fromEntries(props.tableColumns.filter(col => col.type === 'choice').map(col => [col.name, {}]));
})
const uniqueValues = computed(() => {
  // For each column mapped to a choice, find its unique values
  return Object.keys(columnMapping.value).reduce((acc, csvColumn) => {
    const tableColumn = columnMapping.value[csvColumn];
    if (tableColumn && tableColumn.type === 'choice') {
      acc[tableColumn.name] = [...new Set(csvData.value.map(row => row[csvColumn]))];
    }
    return acc;
  }, {});
});

function detectDelimiter(csvContent) {
  const delimiters = [',', ';', '\t'];
  const firstLine = csvContent.split('\n')[0];
  const counts = delimiters.map(delim => (firstLine.split(delim).length - 1));
  return delimiters[counts.indexOf(Math.max(...counts))];
}

function parseFile(file) {
  // Parse the CSV file as [{'Col1': 'ValueRow1Col1', 'Col2': 'ValueRow1Col2'}, {...}]
  const reader = new FileReader();
  reader.onload = (event) => {
    const fileContent = event.target.result;

    if (file.type === 'text/csv') {
      // Handle CSV
      const csvContent = fileContent;
      const delimiter = detectDelimiter(csvContent);
      const rows = csvContent.split('\n').map(row => row.split(delimiter));
      csvData.value = rows.slice(1).map(row => Object.fromEntries(rows[0].map((key, i) => [key, row[i]])));
    } else if (
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || // Excel XLSX
      file.type === 'application/vnd.ms-excel' // Excel XLS
    ) {
      // Handle Excel
      const workbook = XLSX.read(fileContent, { type: 'array' });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const rows = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: true }); // Extract rows as a 2D array
      const firstNonEmptyRowIndex = rows.findIndex(row => row.some(cell => cell !== undefined && cell !== null && cell !== ''));
      const headers = rows[firstNonEmptyRowIndex]
      csvData.value = rows.slice(firstNonEmptyRowIndex + 1)
        .filter(row => row.some(cell => cell !== undefined && cell !== null && cell !== '')) // Exclude entirely empty rows
        .map(row => Object.fromEntries(headers.map((key, i) => [key, row[i]]).filter(([key]) => key !== undefined && key !== null && key !== '')));
    } else {
      console.error('Unsupported file type');
      return;
    }

    step.value = 2;
  };

  // Determine how to read the file based on its type
  if (file.type === 'text/csv') {
    reader.readAsText(file); // CSV files are text
  } else {
    reader.readAsArrayBuffer(file); // Excel files are binary
  }
}

function completeMapping() {
  emit('ok', { csvData: csvData.value, columnMapping: columnMapping.value, choiceMappings: choiceMappings.value });
}
</script>

<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <div v-if="step === 1">
      <q-card class="my-card">
          <q-card-section>
              <div class="text-h6">Sélectionner un fichier</div>
          </q-card-section>
          <q-card-section>
              <input type="file" accept=".csv,.xls,.xlsx" @change="e => parseFile(e.target.files[0])" />
          </q-card-section>
          <q-card-actions align="right">
            <q-btn flat label="Annuler" color="primary" @click="emit('hide')"/>
          </q-card-actions>
      </q-card>
    </div>

    <div v-if="step === 2">
      <q-card>
        <q-card-section>
          <div class="text-h6">Définir les correspondances</div>
          <table style="border-collapse: separate; border-spacing: 1em;">
            <thead>
              <tr>
                <th>Vos colonnes</th>
                <th>Colonne correspondante</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="csvColumn in Object.keys(csvData[0] || {})" :key="csvColumn">
                <td>{{ csvColumn }}</td>
                <td>
                  <select v-model="columnMapping[csvColumn]" style="max-width: 200px; text-overflow: ellipsis;">
                    <option v-for="col in tableColumnOptions" :key="col.name" :value="col" :title="col.name">{{ col.name }}</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Retour" color="primary" @click="step = 1"/>
          <q-btn flat label="Suivant" color="primary" @click="step = 3"/>
        </q-card-actions>
      </q-card>
    </div>

    <div v-if="step === 3">
      <q-card style="width: 400px; max-width: 80vw;">
        <q-card-section>
          <div class="text-h6">Définir les correspondances</div>
        </q-card-section>
        <q-card-section>
          <div v-if="Object.keys(uniqueValues).length === 0">
            Votre fichier est prêt à l'import. {{ csvData.length }} lignes vont être créées.
          </div>
          <div v-for="(values, columnName) in uniqueValues" :key="columnName">
            <div>Colonne : {{ columnName }}</div>
            <table>
              <thead>
                <tr>
                  <th>Valeur dans le CSV</th>
                  <th>Valeur correspondante</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="value in values" :key="value">
                  <td>{{ value }}</td>
                  <td>
                    <select v-model="choiceMappings[columnName][value]">
                      <option v-for="option in tableColumns.find(col => col.name === columnName).choices" :key="option.name" :value="option.name">
                        {{ option.name }}
                      </option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Retour" color="primary" @click="step = 2"/>
          <q-btn flat label="Importer" color="primary" @click="completeMapping"/>
        </q-card-actions>
      </q-card>
    </div>
  </q-dialog>
</template>

<style scoped>
</style>
