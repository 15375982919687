
import { NodeType } from '@/models/database/NodeType'
import { Field } from '@/models/database/Field'

export const defaultValues = {
  'text': '',
  'wysiwyg_text': '',
  'number': '',
  'boolean': false,
  'date': '',
  'group_field': '',
  'choice': '',
  'multi_choice': [],
  'array': [],
  'function': '',
  'file': '',
}

export function createUUID() {
    var dt = new Date().getTime()
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0
      dt = Math.floor(dt / 16)
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })
    return uuid
  }

export function createObjectStructure(modelDefinition): Array<any> {
  const pages = []
  console.log("createObjectStructure", modelDefinition)
  modelDefinition?.children.forEach((modelPage) => {
    const newPage = {
      id: createUUID(),
      modelId: modelPage.id,
      name: modelPage.name,
      type: modelPage.type,
      fields: [],
      children: [],
      selectable: false,
    }
    if (modelPage.type !== NodeType.List) {
      newPage.fields = fieldsFromModelFields(modelPage.fields)
      newPage.children = createObjectStructure(modelPage)
      newPage.selectable = true
    }
    pages.push(newPage)
  })
  return pages
}

export function fieldsFromModelFields(modelFields) {
  return modelFields.map((modelField) => {
    const field: Field = {
      id: createUUID(),
      modelId: modelField.id,
      name: modelField.name,
      type: modelField.type,
      value: defaultValues[modelField.type],
    }
    if (modelField.choices) { field.choices = modelField.choices }
    if (modelField.formula) { field.formula = modelField.formula }
    if (modelField.columns) {
      field.columns = fieldsFromModelFields(modelField.columns)
      // We just don't need the values
      field.columns?.forEach((column) => { delete column.value })
      field.rows = []
    }
    if (modelField.fields) {
      field.fields = fieldsFromModelFields(modelField.fields)
    }
    return field
  })
}

/** Helper function to traverse the object and to find those of its fields that match the question mappings
 * Returns an array of matching fields { variableFieldName: string; value: any }
*/
export function findMatchingFields(node, listNodeId, arrayRowId, questionMappings) {
    // console.log("findMatchingFields", node, listNodeId, questionMappings)
    const matchingFields: { variableFieldName: string; value: any; type: string; pageId: string }[] = [];
    // If the current node is a list and list nodes have been selected (listNodeIds), find them
    if (node.type == 'LIST' && listNodeId) {
          node.children.forEach((child) => {
          if (child.id === listNodeId) {
            console.log("found list node", child)
            // Recursively find matching fields in the list node
            matchingFields.push(...findMatchingFields(child, listNodeId, arrayRowId, questionMappings))
            }
        })
      return matchingFields;
    } else {
      // Check fields in the current node
      if (node.fields) {
        node.fields.forEach(field => {
          // If the current field is an array and array rows have been selected (arrayRowIds), find them
          if (field.type == 'array' && arrayRowId) {
            field.rows.forEach(row => {
              if (row.id === arrayRowId) {
                console.log("found array row", row)
                // Get the values needed as per the question mapping
                matchingFields.push(...findMatchingFields(row, listNodeId, arrayRowId, questionMappings))
              }
            })
          } else if (field.type == 'group_field') {
            matchingFields.push(...findMatchingFields(field, listNodeId, arrayRowId, questionMappings))
          }
          // Check if this field is mapped
          const mapping = questionMappings.find(
            mapping => mapping.databaseObjectModelFieldId === field.modelId
          );
          if (mapping) {
            console.log("found mapping", mapping.variableFieldName, field.value, field.name, field.id, field.modelId)
            matchingFields.push({
              variableFieldName: mapping.variableFieldName,
              value: field.value, // Use the field value from the selected object
              type: field.type,
              pageId: '', // Filled when traversing children. Used for getting the right url link
            });
          }
        });
      }
      // Traverse children
      if (node.children) {
        node.children.forEach(child => {
          let childMatches = findMatchingFields(child, listNodeId, arrayRowId, questionMappings)
          if (childMatches.length > 0) {
            childMatches.forEach(childMatch => {childMatch.pageId = child.id}) // Fill pageId
            matchingFields.push(...childMatches)
          }
        });
      }
      return matchingFields;
    }
  }


/** Helper function to traverse the object and to update those of its fields that match the valueDict
*/
export function updateMatchingFields(node, listNodeId, arrayRowId, questionMappings, valueDict) {
  // console.log("findMatchingFields", node, listNodeId, questionMappings)
  const matchingFields: { variableFieldName: string; value: any }[] = [];
  // If the current node is a list and list nodes have been selected (listNodeIds), find them
  if (node.type == 'LIST' && listNodeId) {
        console.log("check list node", listNodeId)
        node.children.forEach((child) => {
        if (child.id === listNodeId) {
          console.log("found list node", child)
          // Recursively find matching fields in the list node
          updateMatchingFields(child, listNodeId, arrayRowId, questionMappings, valueDict)
          }
      })
  } else {
    // Check fields in the current node
    if (node.fields) {
      node.fields.forEach(field => {
        // If the current field is an array and array rows have been selected (arrayRowIds), find them
        if (field.type == 'array' && arrayRowId) {
          field.rows.forEach(row => {
            if (row.id === arrayRowId) {
              console.log("found array row", row)
              // Get the values needed as per the question mapping
              field.columns.forEach(column => {
                // Change the row.fields[].value based on the valueDict
                const mapping = questionMappings.find(
                  mapping => mapping.databaseObjectModelFieldId === column.modelId
                );
                if (mapping) {
                  // to verify
                  row.fields.find(field => field.name === column.name).value = valueDict[mapping.variableFieldName]
                }
              })
            }
          })
        } else if (field.type == 'group_field') {
          updateMatchingFields(field, listNodeId, arrayRowId, questionMappings, valueDict)
        }
        // Check if this field is mapped
        const mapping = questionMappings.find(
          mapping => mapping.databaseObjectModelFieldId === field.modelId
        );
        if (mapping) {
          console.log("Editing field", mapping.variableFieldName, valueDict[mapping.variableFieldName])
          field.value = valueDict[mapping.variableFieldName]
        }
      });
    }
    // Traverse children
    if (node.children) {
      node.children.forEach(child => {
          updateMatchingFields(child, listNodeId, arrayRowId, questionMappings, valueDict)
      });
    }
    return matchingFields;
  }
}