<script setup lang="ts">
import { ref, Ref, watch, computed, inject, provide } from 'vue'
import { NodeType } from '@/models/database/NodeType'

import TextField from "./rendered_fields/TextField.vue"
import ChoiceField from "./rendered_fields/ChoiceField.vue"
import MultipleChoiceField from "./rendered_fields/MultipleChoiceField.vue"
import NumberField from "./rendered_fields/NumberField.vue"
import DateField from "./rendered_fields/DateField.vue"
import WysiwygTextField from "./rendered_fields/WysiwygTextField.vue"
import ArrayField from "./rendered_fields/ArrayField.vue"
import FunctionField from "./rendered_fields/FunctionField.vue"
import GroupField from './rendered_fields/GroupField.vue'
import FileField from './rendered_fields/FileField.vue'

interface Props {
  node: any
}

const props = defineProps<Props>()

const emit = defineEmits(['update:node', 'remove:node', 'update:label'])
const unsavedChanges: Ref<boolean> = inject("unsavedChanges")

function onFieldChanged(field) {
  // Update the label of the object if it's the name field
  if (props.node.name == "Fiche d'identité de la société" && field.name == "Dénomination sociale") {
    emit('update:label', field.value)
  } else if (props.node.name == "Fiche d'identité" && ["Prénoms", "Nom de famille (de naissance)"].includes(field.name)) {
    let newLabel
    if (field.name == 'Prénoms') {
      let lastName = props.node.fields.find(f => f.name == 'Nom de famille (de naissance)').value
      newLabel = field.value + ' ' + lastName
    } else if (field.name == 'Nom de famille (de naissance)') {
      let firstName = props.node.fields.find(f => f.name == 'Prénoms').value
      newLabel = firstName + ' ' + field.value
    }
    emit('update:label', newLabel)
  }
  console.log("onFieldChanged. props.node:", props.node, "field:", field)
  unsavedChanges.value = true
}

</script>

<template>
  <div v-if="node === null" style="padding: 30px">
    <div class="text-h6">Veuillez sélectionner un élément pour l'éditer.</div>
  </div>
  <div v-else style="height: calc(100% - 160px); margin: 4px; ">

    <q-scroll-area v-if="node != undefined" style="width: 100%; height: calc(100vh - 120px); padding-right: 16px;">

      <div v-if="node.type == NodeType.Object" style="padding: 16px">
        <q-card flat bordered>
          <q-card-section>
            <div class="row items-center no-wrap q-gutter-md">
              <div class="col">
                <div class="text-h6" @click="node.nameEditable = !node.nameEditable" style="cursor: pointer">
                  <q-input v-if="node.nameEditable" v-model="node.name" @keyup.enter="node.nameEditable = false" @blur="node.nameEditable = false" dense autofocus />
                  <span v-else>{{ node.name }}</span>
                </div>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>

      <div v-if="node.fields != undefined" v-for="field in node.fields">
        <TextField v-if="field.type === 'text'" :field="field" @update:field="onFieldChanged" />
        <WysiwygTextField v-if="field.type === 'wysiwyg_text'" :field="field" @update:field="onFieldChanged" />
        <NumberField v-if="field.type === 'number'" :field="field" @update:field="onFieldChanged" />
        <DateField v-if="field.type === 'date'" :field="field" @update:field="onFieldChanged" />
        <ChoiceField v-if="field.type === 'choice'" :field="field" @update:field="onFieldChanged" />
        <MultipleChoiceField v-if="field.type === 'multi_choice'" :field="field" @update:field="onFieldChanged" />
        <FileField v-if="field.type === 'file'" :field="field" @update:field="onFieldChanged" />
        <ArrayField v-if="field.type === 'array'" :key="field.id" :field="field" @update:field="onFieldChanged" />
        <GroupField v-if="field.type === 'group_field'" :field="field" @update:field="onFieldChanged" />
        <FunctionField v-if="field.type === 'function'" :field="field" :otherFields="node.fields" />
        <div style="height: 16px">&nbsp;</div>
      </div>
    </q-scroll-area>
  </div>
</template>

<style lang="scss">
:deep(.text-center) {
  text-align: left;
}

.q-scrollarea__content {
  width: 100%
}
</style>
